/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable  array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable  no-lone-blocks */
/* eslint-disable  no-unused-expressions */
/* eslint-disable  react/self-closing-comp */

import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { useTable, useExpanded, useGlobalFilter } from 'react-table';
import { useDispatch, useSelector } from 'react-redux';
import classes from '../../../components/assetsTable/assetsTable.module.css';
import Loader from '../../../components/loader/loader';
import { closeMaintenanceModalDelete, deleteMaintenance, openMaintenanceModalDelete } from '../../../redux/features/maintenance/maintenanceSlice';
import PopUpDeleteModal from '../../../components/popUpModals/editCategory/popUpDeleteModal';
import PMFilter from '../PMFilter';

function PMTable({
  meterData,
  getSelectedRowwValues,
  iSShowFilter,
  setISShowFilter,
  isLoading,
  setIsLoading
}) {
  const [rowId, setRowId] = useState(null)
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.user);
  const { maintenanceModalDelete } = useSelector(state => state.maintenance);
  const handleDelete = () => {
    dispatch(deleteMaintenance(rowId))
  }
  const columns = React.useMemo(
    () => [
      {
        Header: 'PM ID',
        accessor: 'ticketNumber',
      },

      {
        Header: 'Title',
        accessor: 'title',
      },
      {
        Header: 'Period',
        Cell: ({ row }) => {
          return <div>
            <span><span style={{ marginRight: "16px" }}>Start:</span>  {moment.utc(row?.original?.startSchedule).format("DD MMMM YY")}</span> <br />
            <span><span style={{ marginRight: "21px" }}>End:</span> {moment.utc(row?.original?.endSchedule).format("DD MMMM YY")}</span>
          </div>
        }
      },
      {
        Header: 'Frequency',
        // accessor: "shown_frequency",
        Cell: ({ row }) => {
          return <div>
            <span style={{ fontWeight: "600" }}> {row?.original?.frequencyType}</span> <br />
            <span style={{ color: "#757980" }}>{row?.original?.frequencyNumber}</span>
          </div>
        }
      },
      // {
      //   Header: 'Service Date',
      //   Cell: ({ row }) => {
      //     const date =
      //       moment(row?.original?.start_date).format('YYYY-MM-DD') === 'Invalid date'
      //         ? '—'
      //         :  moment.utc(row?.original?.start_date).format('YYYY-MM-DD');
      //     return (
      //       <>
      //         <span>{date}</span>{' '}
      //         {row?.original?.status !== 'Completed' &&
      //           date !== '—' &&
      //           (moment().diff( moment(row?.original?.start_date).format('YYYY-MM-DD'), 'seconds') <
      //             0 ? (
      //             <i className="far fa-question-circle big-font gcolor ml-2">
      //               <span
      //                 className="tooltiptext bold"
      //                 style={{ width: 'fit-content', padding: '10px', fontWeight: 'bold' }}
      //               >
      //                 {moment(row?.original?.start_date).fromNow(true)} before due
      //               </span>
      //             </i>
      //           ) : (
      //             <i className="fas fa-exclamation-circle overdue">
      //               <span className="tooltiptext" style={{ width: 'fit-content', padding: '10px' }}>
      //                 {moment(row?.original?.start_date).fromNow(true)} overdue
      //               </span>
      //             </i>)
      //           )}

      //       </>
      //     )
      //   },
      // },
      // {
      //   Header: 'Created Date',
      //   Cell: ({ row }) => {
      //     return  moment(row?.original?.created_at).format("YYYY-MM-DD")
      //   } 
      // },
      {
        Header: 'Asset',
        accessor: 'asset',
      },

      {
        Header: 'Location',
        accessor: "location",
      },
      {
        Header: 'Assigned To',
        Cell: ({ row }) => {
          return (
            <span >
              {row?.original?.assignedPersons !== '—' ? 
              (
                row?.original?.assignedPersons?.map(item => {
                  return item?.name
                    // // <img
                    // //   className={classes.assignedImage}
                    // //   src={item?.image}
                    // //   alt={item?.name}
                    // //   title={item?.name}
                    // // />
                    // <span>{item?.name}</span>
                  // );
                })?.join(", ")
              ) 
              : (
                <span className="ml-4">—</span>
              )}
            </span>
          );
        },
      },
      {
        Header: 'Status',
        accessor: 'status',
      },
      {
        Header: 'Priority',
        Cell: ({ row }) => {
          let priorityClass = classes.hPriority;
          if (row.original.priority === 'Medium') {
            priorityClass = classes.mPriority;
          }
          if (row.original.priority === 'Low') {
            priorityClass = classes.lPriority;
          }
          return (
            <>
              {row.original.priority ?
                <span className={`${priorityClass} ${classes.labels}`}>{row.original.priority}</span> : <span className={classes.pr}>—</span>}
              {/* <span className={classes.category} title={row.original.category}>{row.original.category?.length > 10 ? `${row.original.category.slice(0, 10)}...` : row.original.category}</span> */}
            </>
          );
        },
      },
      {
        id: 'delete-table-column',
        Cell: ({ row }) => {
          let deleteBtn = (
            <span
              title="Delete PM"
              onClick={() => {
                dispatch(openMaintenanceModalDelete())
                setRowId(row.original.id)
              }}
              // onClick={() => dispatch(deleteMaintenance(row.original.id))}
              id={row.id}
              className={`tertiary-btn btn ${classes['del-bt']} `}
            >
              <FontAwesomeIcon icon={faTrashCan} />
            </span>
          );
          if (
            user?.role === 'Technical Team' ||
            user?.role === 'Requester' ||
            (user?.role === 'Manager' && row.original?.createdBy !== 'Manager')
          ) {
            deleteBtn = '';
          }
          return deleteBtn;
        },
      },
    ],
    [user],
  );

  const data = meterData;

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, allColumns } = useTable(
    {
      columns,
      data,
      manualPagination: true,
    },
    useGlobalFilter,
    useExpanded,
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <PMFilter
            setISShowFilter={setISShowFilter}
            iSShowFilter={iSShowFilter}
            allColumns={allColumns}
            setIsLoading={setIsLoading}
          />
          <div className={classes.scrollX}>
            <table {...getTableProps()} className={`${classes.table} ${classes.forScroll}` }>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.length !== 0 ? (
                  rows?.map(row => {
                    prepareRow(row);
                    let statusClass;
                    if (row.original.status === 'Open') {
                      statusClass = classes.open;
                    }
                    if (row.original.status === 'In-Progress') {
                      statusClass = classes.inProgress;
                    }
                    if (row.original.status === 'On-Hold') {
                      statusClass = classes.onHold;
                    }
                    if (row.original.status === 'Completed') {
                      statusClass = classes.completed;
                    }
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map(cell => {
                          return (
                            <td
                              {...cell.getCellProps()}
                              onClick={() => getSelectedRowwValues(row.original, cell)}
                            >
                              {cell.column.Header === 'Status' ? (
                                row.original.status === 'Completed' ? (
                                  <span className={statusClass}>
                                    {cell.render('Cell')}
                                    {moment(row.original.completedDate).diff(
                                      moment(row?.original?.start_date).format('YYYY-MM-DD'),
                                      'seconds',
                                    ) < 0 ? (
                                      <i
                                        className="fa fa-check-circle ml-2"
                                        style={{ color: 'green' }}
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-check-circle ml-2"
                                        style={{ color: 'red' }}
                                      ></i>
                                    )}
                                  </span>
                                ) : (
                                  <span className={statusClass}>{cell.render('Cell')}</span>
                                )
                              ) : cell.column.Header === 'Service Date' ? (
                                <span style={{ color: '#db1f48' }}>{cell.render('Cell')}</span>
                              ) : (
                                <span>{cell.render('Cell')}</span>
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    {' '}
                    <td className={classes.noResults} colSpan="9">
                      No Results Found{' '}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          {maintenanceModalDelete && (
            <PopUpDeleteModal
              showModal={maintenanceModalDelete}
              handleClose={() => dispatch(closeMaintenanceModalDelete())}
              handleConfirm={handleDelete}
              modalTitle="Delete maintenance"
              modalBodyText="Are you sure you want to delete this maintenance schedule?"
            />
          )}
        </>
      )}
    </>
  );
}

export default PMTable;
