/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from 'react';
import { Drawer } from '@material-ui/core';
import moment from 'moment';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowTurnDown, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import trachIcoN from "../../../assets/img/trashIcon.png"
import plusIcon from "../../../assets/img/plusIcon.png"
import {
  setAssignedTo,
  setCategory,
  setPriority,
  setTab,
  // setTicketType,
  setTitle,
  setDescription,
  createWorkOrder,
  addAttachment,
  setTime,
  setDueFinish,
  getDueDate,
  setFixedDate,
  setWorkOrderType,
  setMeterId,
  setIsSLAEnabled,
  setSWorkOrderStartDateTime,
  setStartedDate,
  setRequestor,
} from '../../../redux/features/workOrder/workOrderSlice';
// import { selectActions } from '../../../redux/features/selectOptions/selectSlice';
import {
  SelectAsset,
  SelectLocation,
  SelectMeter,
  SelectPersons,
  SelectSite,
  SelectTemplate,
  // SelectWOType,
} from '../../../components/select/select';
import { getWorkOrderTypeByAuthentication } from '../../../redux/features/workOrderType/workOrderTypeSlice';
import { getMetersByPagination } from '../../../redux/features/meter/meterSlice';
import { getPagination } from '../../../redux/features/checklist/checklistSlice';
import FileIcon from '../../../assets/svg-icons/FileIcon';
import AttachmentIcon from '../../../assets/svg-icons/AttachmentIcon';
// import { getAssetsByRows } from '../../../redux/features/asset/assetSlice';


function PreviewTask({ item }) {
  return <div className='task-preview-container'>
    <div className='adjust-group p-0 mb-0'>
      <div className='group-name'>
        {item.name}
      </div>
      <div className='checklist-select'>
        <select disabled>
          <option selected value="Status" >Status</option>
          <option value="Done">Done</option>
          <option value="Not Done">Not Done</option>
          <option value="Fail">Fail</option>
          <option value="Pass">Pass</option>
        </select>
      </div>
      <div className='cursor-pointer ml-4 icon-preview'>
        <FileIcon gray />
      </div>
      <div className='cursor-pointer ml-4 icon-preview'>
        <AttachmentIcon gray />
      </div>
    </div>
  </div>
}

function ChecklistGroupPreview({ data }) {
  return <div>
    <div style={{ margin: "15px 0" }}>
      <h5>{data?.title}</h5>
    </div>
    {data?.items?.map(item => {
      return <PreviewTask key={item?.id} data={data} item={item} />
    })}
  </div>
}

function ChecklistSearching({ checklistSearch, setchecklistSearch, handelChecklistSearchClick, checklistSearchBox, setchecklistSearchBox, ranges, handelEnterChecklist }) {
  const [filteredChecklist, setFilteredChecklist] = useState("")
  const checklists = useSelector((state) => state?.checklists?.checklists?.results);
  useEffect(() => {
    const rangesIds = ranges?.map(item => item?.title) || []
    setFilteredChecklist(checklists.filter(item => rangesIds?.includes(item?.title) === false))
  }, [checklists])

  const dispatch = useDispatch()
  const handelChecklistSearch = async (e) => {
    setchecklistSearchBox(true)
    const { value } = e.target
    setchecklistSearch(value)
    await dispatch(
      getPagination({ rowPerPage: 10, currentPage: 1, searchValue: value }),
    );
    if (!value) {
      setchecklistSearchBox(false)
    }
  }
  const handleEnter = (e) => {
    if (e.key === 'Enter' && checklistSearch) {
      handelEnterChecklist(checklistSearch);
      setchecklistSearch("")
    }
  }

  const handelOpenbox = () => {
    setchecklistSearchBox(!checklistSearchBox)
  }

  return <div className='Checklist-Searching'>
    <div className="Checklist-Searching-box">
      <input type="text" placeholder='Search or add new checklist'
        value={checklistSearch}
        onChange={handelChecklistSearch}
        onKeyPress={handleEnter}
      />
      <span onClick={handelOpenbox}>
        {checklistSearchBox ? <span style={{ fontSize: "28px", color: "#f63854" }}>-</span> : <img src={plusIcon} alt="plusIcon" />}
      </span>
    </div>
    {checklistSearchBox && <div className='tck-overlay' onClick={() => setchecklistSearchBox(!checklistSearchBox)} />}
    {checklistSearchBox && <div className='Checklist-Searching-results'>
      {filteredChecklist?.length > 0 ? filteredChecklist?.map(checklist => {
        return <div className='Checklist-Searching-results-result'
          onClick={() => handelChecklistSearchClick(checklist)}>
          <h6>{checklist?.title}</h6>
          <span>{checklist?.items_count} Tasks</span>
        </div>
      }) : <div style={{ textAlign: "center" }}>Press enter to create checklist</div>}
    </div>}
  </div>
}


function SelectStatusType({ setStatusType, handelStatusClick }) {
  const [isShowTypes, setIsShowTypes] = useState(false)
  return <div className='Status-Type'>
    <div className="Status-Type-box"
      onClick={() => setIsShowTypes(!isShowTypes)}
    >
      <p className='mb-0'>Status type</p>
      <span className={isShowTypes ? "rotateIcon" : ''}
      >
        <FontAwesomeIcon icon={faAngleDown} />
      </span>
    </div>
    {isShowTypes && <div className='tck-overlay' onClick={() => setIsShowTypes(!isShowTypes)} />}
    {isShowTypes && <div className='Status-Type-results'>
      <div className='Status-Type-results-result' onClick={() => {
        setStatusType("typeA")
        setIsShowTypes(false)
        handelStatusClick("typeA")
      }}>
        <h6>Status type A</h6>
        <span>Options (Done/Not done/Pending/On hold)</span>
      </div>
      <div className='Status-Type-results-result mt-2' onClick={(e) => {
        setStatusType("typeB")
        setIsShowTypes(false)
        handelStatusClick("typeB")
      }}>
        <h6>Status type B</h6>
        <span>Options (Passed/Failed/Pending/On hold)</span>
      </div>
    </div>
    }
  </div>
}

function TaskEdit({ handelDeleteTask, index, i, item, handelTaskChange }) {
  const [taskValue, setTaskValue] = useState(item?.name)

  // const handelUpateItem = async (e)=>{
  //   if (e.key === "Enter" && taskValue) {
  //     await dispatch(updateChecklistItem(
  //       { id: item?.id, name: taskValue, result:item.result, remarks: item.remarks, type: item?.type}
  //     ));
  //     dispatch(reset());
  //   }
  // }


  return <div className='tasks-wraper-task'>
    <div className='task-name'>
      <input type="text" value={taskValue}
        onChange={(e) => setTaskValue(e.target.value)}
        placeholder='Task'
        onKeyPress={(e) => handelTaskChange(e, index, i, taskValue)}
      />
    </div>
    <div className='ml-4'>
      <div
        style={{ cursor: "pointer" }}
        id="add_check_list_trash"
        onClick={() => handelDeleteTask(index, i)}
        title="Delete Task">
        <img style={{ width: "15px" }}
          src={trachIcoN} alt="trachIcon" />
        <div id="add_check_list_trash"> </div>
      </div>
    </div>
  </div>
}

function ChecklistGroupEdit({ data, handleSelectRange, ranges, setRanges, index }) {
  const dispatch = useDispatch()
  const [taskName, setTaskName] = useState("")
  const [statusType, setStatusType] = useState("")


  const handleAddTask = (e, indexo) => {
    const { value } = e.target
    if (e.key === 'Enter' && value) {
      const putData = ranges.map((range, i) => {
        if (i === indexo) {
          return { ...range, items: [...range.items, { name: value, type: statusType || "typeA" }] }
        }
        return range
      })
      setRanges(putData)
      setTaskName("")
    }
    setStatusType("")
  }

  const handelStatusClick = (status, indexo) => {
    if (taskName) {
      const putData = ranges.map((range, i) => {
        if (i === indexo) {
          return { ...range, items: [...range.items, { name: taskName, type: status || "typeA" }] }
        }
        return range
      })
      setRanges(putData)
      setTaskName("")
    } else {
      toast.warning('Please fill Task Name field and try again', {
        toastId: 'CreatedEventWarning',
      });
    }
  }

  const handelDeleteTask = (checklistIndex, itemIndex) => {
    setRanges(ranges.map((item, cIndex) => {
      if (cIndex === checklistIndex) {
        const items = item.items.filter((f, fIndex) => fIndex !== itemIndex)
        return { ...item, items }
      }
      return item
    }))
  }

  const handelTaskChange = (e, itemIndex, taskItem, taskValue) => {
    console.log(itemIndex, taskItem, taskValue);

    if (e.key === "Enter" && taskValue) {
      const putData = ranges.map((range, i) => {
        if (i === itemIndex) {
          const items = range?.items.map((item, j) => {
            if (j === taskItem) {
              return { ...item, name: taskValue }
            }
            return item
          })
          return { ...range, items }
        }
        return range
      })
      setRanges(putData)
    }
  }

  return <div>
    <div className="checkbox-wrapper-title mt-2 mb-2">
      <div className='flex'>
        <input
          type="checkbox"
          checked={data?.selected}
          onChange={() => handleSelectRange(data.id)}
        />
        <h6 className=''>{data?.title}</h6>
      </div>
      <button className="new-btn"
        style={{ borderRadius: "5px", padding: "5px" }}
        type='button'
        onClick={() =>
          setRanges(ranges.filter((_, rin) => rin !== index))
        }
      >
        <img style={{ width: "15px" }}
          src={trachIcoN} alt="trachIcon" />
      </button>
    </div>
    <div className='tasks-wraper'>
      {data?.items?.map((item, i) => {
        return <TaskEdit
          data={data}
          i={i}
          index={index}
          item={item}
          handleAddTask={handleAddTask}
          handelDeleteTask={handelDeleteTask}
          handelTaskChange={handelTaskChange}
        />
      })}
      <div className="add-task-input">
        <input
          type="text"
          placeholder="Add additional tasks"
          onKeyPress={(e) => handleAddTask(e, index)}
          onChange={(e) => setTaskName(e.target.value)}
          value={taskName}
        />
        <div style={{ display: "flex", alignItems: "center" }}>
          {/* <SelectStatusType
            setStatusType={setStatusType}
            handelStatusClick={(type) => handelStatusClick(type, index)}
          /> */}
          <span> <FontAwesomeIcon icon={faArrowTurnDown} /></span>
        </div>
      </div>
    </div>
  </div>
}






function AddWOModal({ openModalCreate, closeModalCreate, checklistData, createdFrom }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDueFinish(moment(new Date()).format('YYYY-MM-DD')));
    dispatch(setTime(moment(new Date()).format('HH:mm')));
    dispatch(setFixedDate(moment(new Date()).format('YYYY-MM-DD')));
    dispatch(getWorkOrderTypeByAuthentication());
    dispatch(getMetersByPagination({ rowPerPage: 10, currentPage: 1, SearchVal: "" }));
  }, []);

  const handelTabs = (e) => {
    dispatch(setTab(e.target.id));
  };

  const handelTitle = (e) => {
    dispatch(setTitle(e.target.value));
  };

  const handelRquestor = (e) => {
    dispatch(setRequestor(e.target.value));
  };

  const handelCategory = (e) => {
    dispatch(setCategory(e.target.value));
  };
  const handelWorkOrderType = (e) => {
    dispatch(setWorkOrderType(e.target.value));
  };

  const handelDescription = (e) => {
    dispatch(setDescription(e.target.value));
  };
  const handleDateTime = (e) => {
    dispatch(setTime(e.target.value));
  };
  const handleDueFininsh = (e) => {
    dispatch(setDueFinish(e.target.value));
  };

  const handleWorkOrderStartDate = (e) => {
    dispatch(setStartedDate(e.target.value));
  };
  const handleWorkOrderStartDateTime = (e) => {
    dispatch(setSWorkOrderStartDateTime(e.target.value));
  };

  const handelFile = (e) => {
    dispatch(addAttachment([{ file: e.target.files[0] }]));
  };
  const { meters } = useSelector(state => state.meter);
  const assetListSearch = useSelector(state => state.asset?.assetListSearch);
  const workOrderCategory = useSelector(state => state.workOrderCategory?.workOrderCategories);
  const workOrderTypes = useSelector(state => state.workOrderType?.workOrderTypes);
  const teams = useSelector(state => state.teams?.teams?.results);
  const { locations, sublocations } = useSelector(state => state.location);
  const { selectedSite, idOfSite, idOfLocation, idOfAsset, assetInfo } = useSelector(
    state => state.selectOptions,
  );


  const [isEditing, setIsEditing] = useState(true)
  const [checklistSearch, setchecklistSearch] = useState("")
  const [ranges, setRanges] = useState([]);
  const [checklistSearchBox, setchecklistSearchBox] = useState(false)


  const handelChecklistSearchClick = async (checklist) => {
    const data = [...ranges, {
      title: checklist?.title,
      items: checklist?.items,
      is_template: false,
      selected: false
    }]

    setRanges(data)
    setchecklistSearchBox(false)
    setchecklistSearch("")

  }
  const handelEnterChecklist = async (checklistName) => {
    const data = [...ranges, {
      title: checklistName,
      items: [],
      is_template: false,
      selected: false
    }]
    setRanges(data)
    setchecklistSearchBox(false)
  }

  const handleSelectAll = (e) => {
    const isSelected = e.target.checked;
    setRanges(ranges.map(range => {
      return {
        ...range,
        selected: isSelected
      }
    }));
  };

  const handelDeleteBySelect = () => {
    const deleteIds = []
    ranges.forEach((range, index) => {
      if (range.selected) {
        deleteIds.push(index)
      }
    })
    setRanges(ranges.filter((_, index) => !deleteIds.includes(index)))
  };

  const handleSelectRange = (index) => {
    setRanges(ranges.map((range, i) =>
      i === index
        ? {
          ...range,
          selected: !range.selected,
        }
        : range
    ));
  };

  const {
    title,
    description,
    priority,
    category,
    assigned_to,
    attachments,
    due_finish,
    workOrderStartDateTime,
    time,
    tab,
    // ticketType,
    dueDate,
    fixedDate,
    workOrderType,
    meterId,
    rquestor,
    isSLAEnabled,
    start_date
  } = useSelector((state) => state.workOrder);
  const handelPriority = (e) => {
    dispatch(setPriority(e.target.value));
    dispatch(getDueDate({ priority: e.target.value, date: fixedDate }));
  };
  useEffect(() => {
    if (dueDate) {
      dispatch(setDueFinish(moment.utc(dueDate?.due_date).format('YYYY-MM-DD')));
      dispatch(setTime(moment.utc(dueDate?.due_date).format('HH:mm')));
    }
  }, [dueDate]);
  const handelSave = async (e) => {
    e.preventDefault();
    // let meta_type;
    const formData = new FormData();
    if (title) {
      formData.append('title', title);
    }

    if (rquestor) {
      formData.append('requestor_email', rquestor);
    }
    if (description) {
      formData.append('description', description);
    }
    if (priority) {
      formData.append('priority', priority);
    }
    if (category) {
      formData.append('category', category);
    }
    formData.append('display_cost', true);
    if (workOrderType) {
      formData.append('cust_type', workOrderType);
    }
    formData.append('enable_sla', isSLAEnabled);

    if (!isSLAEnabled && start_date) {
      formData.append(
        'start_date',
        moment.utc(`${start_date} ${workOrderStartDateTime}`).format('YYYY-MM-DDTHH:mm:ss.SSZ'),
      );
    }

    if (due_finish) {
      formData.append(
        'due_finish',
        moment.utc(`${due_finish} ${time}`).format('YYYY-MM-DDTHH:mm:ss.SSZ'),
      );
    }
    if (assigned_to?.length > 0) {
      assigned_to?.forEach((ele) => formData.append('assigned_to', ele?.value));
    }
    if (attachments?.length > 0) {
      for (let i = 0; i < attachments.length; i += 1) {
        if (attachments[i].file.type.includes('image')) {
          formData.append(`attachment[${i}][photo]`, attachments[i].file);
        } else {
          formData.append(`attachment[${i}][file]`, attachments[i].file);
        }
      }
    }

    if (ranges.length > 0) {
      for (let index = 0; index < ranges.length; index += 1) {
        const element = ranges[index];
        formData.append(`checklist[${index}].is_template`, false);
        formData.append(`checklist[${index}].title`, element?.title);
        if (element?.items?.length > 0) {
          for (let i = 0; i < element?.items?.length; i += 1) {
            formData.append(`checklist[${index}].items[${i}][name]`, element.items[i].name);
            formData.append(`checklist[${index}].items[${i}][type]`, element.items[i].type);
          }
        }
      }
    }
    if (idOfSite) {
      formData.append('location', idOfSite);
      // formData.append('meta_type', 'Location');
      // meta_type = 'Location';
    }
    if (idOfAsset) {
      formData.append('asset', idOfAsset);
      // formData.append('meta_type', 'Asset');
      // meta_type = 'Asset';
    }
    if (idOfLocation) {
      formData.append('sub_location', idOfLocation);
    }
    if (createdFrom) {
      formData.append('created_from', createdFrom);
    }
    if (meterId) {
      formData.append('meter', meterId?.value);
    }
    if (
      title &&
      due_finish &&
      assigned_to[0] &&
      category
    ) {
      if (rquestor && !rquestor.includes("@")) {
        toast.warning('Please enter a valid email for Rquestor field', {
          toastId: 'CreatedEventWarning',
        });
        return
      }
      if (!isSLAEnabled && !start_date) {
        toast.warning('Please enter a valid input on Start Date field', {
          toastId: 'CreatedEventWarning',
        });
        return
      }
      await dispatch(createWorkOrder(formData));
    } else {
      toast.warning('Please fill the required fields and submit again', {
        toastId: 'CreatedEventWarning',
      });
    }
  };


  const allSubLocations = [];
  return (
    <Drawer
      anchor="right"
      open={openModalCreate}
      onClose={closeModalCreate}
      PaperProps={{
        style: {
          width: '100%',
          maxWidth: '600px',
          height: '100%',
        },
      }}
    >
      <div className="h-100 w-100">
        <div className="h-100">
          <div className="navbar sticky-top col-lg-12 m-0 modal-header-background page-profile-header pr-0">
            <div className="col-12 mb-2">
              <div className="row px-2">
                <div className="my-auto">
                  <b>Add Work Order</b>
                </div>

                <button
                  onClick={closeModalCreate}
                  type="button"
                  className="btn secondary-btn ml-auto"
                  data-dismiss="modal"
                >
                  <div className="fas fa-times"></div>
                </button>
              </div>
            </div>

            <div className="row justify-content-start mt-auto col-lg-12 col-sm-12 ">
              <ul className="nav nav-pills" id="custom-tabs-two-tab" role="tablist">
                <li className="nav-item">
                  <span
                    onClick={handelTabs}
                    className={`nav-link ${tab === 'maintenance-tab' && 'active'} cursor-pointer`}
                    id="maintenance-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="custom-tabs-two-meter"
                    aria-selected="false"
                  >
                    Work Order Details <label className="font-danger m-0">*</label>
                  </span>
                </li>
                {/* <li className="nav-item">
                        <span onClick={handelTabs} 
                        className={`nav-link ${tab === "schedule-tab" && "active"} cursor-pointer`} id="schedule-tab" data-toggle="tab"  
                        role="tab" aria-controls="custom-tabs-two-schedule" aria-selected="false">Maintenance Schedule  <label className="font-danger m-0">*</label></span>
                      </li> */}
                <li className="nav-item">
                  <span
                    onClick={handelTabs}
                    className={`nav-link ${tab === 'attachment-tab' && 'active'} cursor-pointer`}
                    id="attachment-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="custom-tabs-two-attachment"
                    aria-selected="false"
                  >
                    Attachments
                  </span>
                </li>
                <li className="nav-item">
                  <span
                    onClick={handelTabs}
                    className={`nav-link ${tab === 'checklist-tab' && 'active'} cursor-pointer`}
                    id="checklist-tab"
                    data-toggle="tab"
                    role="tab"
                    aria-controls="custom-tabs-two-attachment"
                    aria-selected="false"
                  >
                    Checklist
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="" style={{ minHeight: '78.5%' }}>
            <div className="tab-content col-lg-12 col-sm-12" id="custom-tabs-two-tabContent">
              {tab === 'maintenance-tab' && (
                <div
                  id="maintenance-tab"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-two-maintenance-tab"
                >
                  <div className="card-body">
                    <div className="row pb-5">
                      <div className=" col-lg-12 col-sm-12 row">
                        {/* <label className="font-weight-normal col-12">
                          Select Type: <label className="font-danger m-0">*</label>
                        </label>
                         <div className="col-4">
                          <input
                            className="mr-2"
                            type="radio"
                            checked={ticketType === 'assetCheck'}
                            required
                            name="meter_type"
                            onClick={handelTicketType}
                            id="assetCheck"
                          />
                          Assets
                        </div>
                        <div className="col-4">
                          <input
                            className="mr-2"
                            checked={ticketType === 'locationCheck'}
                            type="radio"
                            name="meter_type"
                            onClick={handelTicketType}
                            id="locationCheck"
                          />
                          Location{' '}
                        </div>
                        <div className="col-4">
                          <input
                            className="mr-2"
                            checked={ticketType === 'generalCheck'}
                            type="radio"
                            name="meter_type"
                            onClick={handelTicketType}
                            id="generalCheck"
                          />
                          General{' '}
                        </div> */}

                        <div className="col-lg-12 col-sm-12 pt-3">
                          <label className="font-weight-normal">
                            Work Order Title <label className="font-danger m-0">*</label>
                          </label>
                          <input
                            required
                            name="meter_name"
                            type="text"
                            className="form-control mb-2"
                            placeholder="Work Order Title"
                            value={title}
                            onChange={handelTitle}
                          />
                          <div className="col-lg-12 col-sm-12 m-0">
                            <span className="font-danger" id="meter_error"></span>
                          </div>
                        </div>

                        <div className="col-lg-12 col-sm-12 pt-3">
                          <label className="font-weight-normal">Description</label>
                          <textarea
                            name="description"
                            className="form-control mb-2"
                            placeholder="Description"
                            id="description"
                            onChange={handelDescription}
                            value={description}
                          ></textarea>
                        </div>
                        <div className="col-lg-12 col-sm-12 pt-3">
                          <div id="ifAsset" className="col-12 p-0">
                            <label className="font-weight-normal">Assets</label>
                            <SelectAsset
                              width="100%"
                              setAssignedTo={setAssignedTo}
                              assetData={assetListSearch}
                            />
                          </div>
                        </div>
                        <div className="col-lg-12 col-sm-12 pt-3">
                          <SelectSite locations={locations} assetInfo={assetInfo} width="100%" noStar />
                          {selectedSite && (
                            <div className="col-12 p-0" id="building_show">
                              <SelectLocation
                                assetInfo={assetInfo}
                                subLocation={sublocations?.results}
                                allSubLocations={allSubLocations}
                                width="100%"
                                noStar
                              />
                            </div>
                          )}
                        </div>
                      </div>


                      <div className="col-lg-12 col-sm-12 pt-3">
                        <label className="font-weight-normal">
                          Meter
                        </label>
                        <div className="col-12 p-0">
                          <SelectMeter
                            meters={meters?.results}
                            meterId={meterId}
                            setMeterId={setMeterId}
                            setAssignedTo={setAssignedTo}
                            locations={locations}
                          />
                        </div>
                      </div>

                      {/* {ticketType === 'assetCheck' && (
                        <div className="col-lg-12 col-sm-12 pt-3">
                          <div id="ifAsset" className="col-12 p-0">
                            <label className="font-weight-normal">Assets</label>
                            <SelectAsset
                              width="100%"
                              setAssignedTo={setAssignedTo}
                              assetData={assetListSearch}
                            />
                          </div>
                        </div>
                      )} */}

                      {/* {ticketType === 'locationCheck' && (
                        <div className="col-lg-12 col-sm-12 pt-3">
                          <div id="ifLocation" className="col-12 p-0">
                            <SelectSite locations={locations} width="100%" noStar />
                            {selectedSite && (
                              <div className="col-12 p-0" id="building_show">
                                <SelectLocation
                                  subLocation={sublocations?.results}
                                  allSubLocations={allSubLocations}
                                  width="100%"
                                  noStar
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      )} */}

                      <div className="col-lg-12 col-sm-12 pt-3">
                        <label className="font-weight-normal">
                          Assigned to <span className="font-danger">*</span>
                        </label>
                        <div className="col-12 p-0">
                          <SelectPersons
                            teams={teams}
                            assignedTo={assigned_to}
                            setAssignedTo={setAssignedTo}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12 pt-3">
                        <label className="font-weight-normal">
                          Requestor email
                        </label>
                        <input
                          name="requestor"
                          type="email"
                          className="form-control mb-2"
                          placeholder="Requestor"
                          value={rquestor}
                          onChange={handelRquestor}
                        />
                        <div className="col-lg-12 col-sm-12 m-0">
                          <span className="font-danger" id="meter_error"></span>
                        </div>
                      </div>
                      <div className="col-lg-12 col-sm-12 pt-3">
                        <label className="font-weight-normal">
                          Category<label className="font-danger m-0">*</label>
                        </label>
                        <select
                          className="form-control select-dash custom-select2 mb-2"
                          name="category"
                          id="category2"
                          onChange={handelCategory}
                          value={category}
                        >
                          <option value="" disabled selected>
                            --SELECT--
                          </option>

                          {workOrderCategory &&
                            workOrderCategory.length > 0 &&
                            workOrderCategory.map((cat) => (
                              <option key={cat.name} value={cat.name}>
                                {cat.name}
                              </option>
                            ))}
                        </select>
                        <label className="font-weight-normal">Sub Category</label>
                        <select
                          className="form-control select-dash custom-select2 mb-2"
                          name="category"
                          id="category2"
                          onChange={handelWorkOrderType}
                          value={workOrderType}
                        >
                          <option value="" disabled selected>
                            --SELECT--
                          </option>

                          {workOrderTypes &&
                            workOrderTypes.length > 0 &&
                            workOrderTypes.map((type) => (
                              <option key={type.name} value={type.name}>
                                {type.name}
                              </option>
                            ))}
                        </select>
                        <div className="col-lg-12 col-sm-12 pt-3">
                          <label className="font-weight-normal ">Priority</label>
                          <select
                            className="form-control select-dash custom-select2 mb-2"
                            name="priority2"
                            id="priority2"
                            onChange={handelPriority}
                            value={priority}
                          >
                            <option value="" disabled selected>
                              --SELECT--
                            </option>
                            {['Critical', 'High', 'Medium', 'Low'].map((val) => (
                              <option key={val} value={val}>
                                {val}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-lg-12 col-sm-12 pt-2 pb-2">
                          <div style={{ display: "flex", alignItems: "center" }}>
                            <span>
                              {/* <input type="checkbox" className='cost-status'
                                onChange={() => dispatch(setIsSLAEnabled(!isSLAEnabled))} checked={isSLAEnabled}
                              /> */}
                              <label className="weekly-frequency-switch black-switch mr-3">
                                <input id='checkbox-toggle'  type="checkbox"
                                  onChange={() => dispatch(setIsSLAEnabled(!isSLAEnabled))} checked={isSLAEnabled} />
                                <span className="weekly-frequency-slider"  />
                              </label>
                            </span>
                            <label htmlFor='checkbox-toggle' className="font-weight-normal">Enable SLA</label>
                          </div>
                        </div>
                        {!isSLAEnabled && <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <label className="font-weight-normal">
                              Start Date<label className="font-danger m-0">*</label>
                            </label>
                            <input
                              type="date"
                              className="form-control mb-2"
                              name="service_date"
                              id="meter_service_date"
                              placeholder="Date"
                              required
                              value={start_date}
                              onChange={handleWorkOrderStartDate}
                            />
                          </div>
                          <div className=" col-lg-6 col-md-6 col-sm-12">
                            <label className="font-weight-normal">
                              {' '}
                              <label className="font-danger m-0"></label>
                            </label>
                            <input
                              type="time"
                              className="form-control mb-2"
                              name="service_date"
                              id="PM_service_date"
                              placeholder="End Date"
                              required
                              value={workOrderStartDateTime}
                              onChange={handleWorkOrderStartDateTime}
                            />
                          </div>
                        </div>}
                        <div className="row">
                          <div className="col-lg-6 col-md-6 col-sm-12">
                            <label className="font-weight-normal">
                              Due Date <label className="font-danger m-0">*</label>
                            </label>
                            <input
                              type="date"
                              className="form-control mb-2"
                              name="service_date"
                              id="meter_service_date"
                              placeholder="Date"
                              required
                              value={due_finish}
                              onChange={handleDueFininsh}
                            />
                          </div>
                          <div className=" col-lg-6 col-md-6 col-sm-12">
                            <label className="font-weight-normal">
                              {' '}
                              <label className="font-danger m-0"></label>
                            </label>
                            <input
                              type="time"
                              className="form-control mb-2"
                              name="service_date"
                              id="PM_service_date"
                              placeholder="End Date"
                              required
                              value={time}
                              onChange={handleDateTime}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <p className="font-danger float-center" id="code_error"></p>
                  </div>
                </div>
              )}

              {tab === 'attachment-tab' && (
                <div
                  id="attachment-tab"
                  role="tabpanel"
                  aria-labelledby="custom-tabs-two-attachment-tab"
                >
                  <div className="card-body">
                    <div style={{ background: '#f8f8f8' }}>
                      <input
                        type="file"
                        id="file-input"
                        onChange={handelFile}
                        multiple
                        name="file-input"
                        accept="image/*"
                      />
                      <label id="file-input-label" htmlFor="file-input">
                        Choose Files
                      </label>
                    </div>
                    {attachments?.length > 0 && (
                      <p style={{ marginBottom: '5px', fontSize: '20px' }}>Files Choosen</p>
                    )}
                    <ul>
                      {attachments?.map((file, index) => (
                        <span style={{ marginRight: '10px' }} key={index}>
                          {file?.file?.name}
                        </span>
                      ))}
                    </ul>
                  </div>
                </div>
              )}

              {tab === 'checklist-tab' && (
                <div id="checklist-tab-edit" role="tabpanel" aria-labelledby="custom-tabs-two-checklist-tab">
                  <div>
                    <div className="col-lg-12 col-sm-12 pb-5" id="">
                      <div className="card-box card-body mx-auto col-lg-12 pb-5" id="CL-view">
                        <div className='checklist-header'>
                          <div>
                            <div style={{ fontWeight: "600" }}> Checklist</div>
                            <p>{ranges?.length} Checklist issued</p>
                          </div>
                          <button type="button"
                            onClick={() => setIsEditing(!isEditing)}
                            className='new-btn btn'
                            style={{
                              color: "#f63854", borderRadius: "5px",
                              marginRight: "0px", fontWeight: "600",
                              fontSize: "14px"
                            }}                          >
                            {isEditing ? "Preview" : "Edit Checklist"}
                          </button>
                        </div>
                        {isEditing ? <>
                          <div className='checklist-drop-down'>
                            <ChecklistSearching
                              handelChecklistSearchClick={handelChecklistSearchClick}
                              checklistSearchBox={checklistSearchBox}
                              setchecklistSearchBox={setchecklistSearchBox}
                              ranges={ranges}
                              handelEnterChecklist={handelEnterChecklist}
                              checklistSearch={checklistSearch}
                              setchecklistSearch={setchecklistSearch}
                            />
                            <div style={{ color: "#999999", marginTop: "5px", fontSize: "12px", fontStyle: "italic" }}>
                              *Enter a checklist name to create a new custom checklist
                            </div>
                          </div>
                          {ranges?.length !== 0 &&
                            <div className="select-all">
                              <div className="checkbox-wrapper-title">
                                <input
                                  type="checkbox"
                                  onChange={handleSelectAll}
                                  checked={ranges?.every(range => range?.selected) && ranges?.length !== 0}
                                />
                                <h6 className='mt-2'>Select all</h6>
                              </div>
                              <button className="new-btn"
                                style={{ borderRadius: "5px", padding: "5px" }}
                                type='button'
                                onClick={handelDeleteBySelect}
                              >
                                <img style={{ width: "15px" }}
                                  src={trachIcoN} alt="trachIcon" />
                              </button>
                            </div>
                          }
                          <div style={{ width: '100%', marginBottom: "20px", marginTop: "20px" }}>
                            {ranges && ranges.length > 0 ? ranges.map((data, index) => (
                              <ChecklistGroupEdit
                                key={index}
                                data={data}
                                handleSelectRange={() => handleSelectRange(index)}
                                ranges={ranges}
                                setRanges={setRanges}
                                index={index}
                              />
                            )) :
                              <div className='wrapper-no-checklist'>
                                <p className='no-checklist'>No checklist issued yet</p>
                              </div>
                            }
                          </div>
                        </>
                          :
                          <div style={{ width: '100%', marginBottom: "20px", marginTop: "20px" }}>
                            {ranges && ranges.length > 0 ? ranges.map((data) => (
                              <ChecklistGroupPreview key={data?.id} data={data} />
                            )) :
                              <div className='wrapper-no-checklist'>
                                <p className='no-checklist'>Nothing to preview</p>
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className=" m-0 p-2 page-profile-footer">
            <button onClick={handelSave} className="btn primary-btn" type="submit">
              Save
            </button>
          </div>
        </div>
      </div>
    </Drawer>
  );
}

export default AddWOModal;

{
  /* <div className="modal fade" id="modal-delete-meter">
        <div className="modal-dialog">
            <div className="modal-content">
                <div className="modal-header">
                    <h4 className="modal-title">Delete meter</h4>
                    <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div className="modal-body">
        <input type="hidden" id="delete-meter-id" />
        <input type="hidden" id="csrf-meter-delete" value=" csrf_token "/>
        <p>
            Are you sure you want to delete this
            meter?
        </p>
    </div>
    
    <div className="modal-footer float-right ">
        <a id="delete-meter-data" href="/delete-meter/obj_s.id/" style = "color:white">
            <button className="btn btn-primary mx-0" 
                    type="button"
                    
                    id="delete-meter-data"
                    href = "/delete-meter/obj_s.id/"
                    onClick="delete_meter()">
                    Delete
            <a id="delete-meter-data" href="/delete-meter/obj_s.id/"></a></button>
        
        <button type="button"
                className="btn secondary-btn"
                
                data-dismiss="modal">
                Cancel
        </button>
    </div>
    </div>
    </div>
    </div>
    <div className="modal fade" id="modal-default">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title">Delete Attachment</h4>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <p>Are you sure you want to delete this Attachment?</p>
            </div>
            <div className="modal-footer float-right">
                <a id="delete-data" href="#">
                    <button type="button" className="btn btn-primary">Delete</button>
                </a>
                <button type="button" className="btn secondary-btn"  data-dismiss="modal">Cancel</button>
            </div>
          </div>
        </div>
    </div> */
}
