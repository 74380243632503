import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';

import { useSelector, useDispatch } from 'react-redux';
import { getManagerPositionByAuthentication } from '../../redux/features/managerPosition/managerPositionSlice';
import { getDepartmentsByAuthentication } from '../../redux/features/department/departmentSlice';
import { updateTeam } from '../../redux/features/teams/teamSlice';

export default function ManegerPopUp() {
  const statuses = [true, false]
  const { departments } = useSelector(state => state.department);
  const { user } = useSelector((state) => state.user);
  const { team } = useSelector(state => state.teams);
  // const [status, setStatus] = useState(team.status);
  const [deps, setDeps] = useState([]);
  const [mangers, setManagers] = useState([]);
  const { managers } = useSelector(state => state.managerPosition);
  const [userInfo, setUserInfo] = useState({
    first_name: team.first_name,
    last_name: team.last_name,
    profile: {
      department: team.profile.department?.id || null,
    },
    manager: {
      position: team.manager.position?.id || null,
      hourly_rate: team.manager.hourly_rate,
    },
    status:team.status
  });

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDepartmentsByAuthentication());
    dispatch(getManagerPositionByAuthentication());
  }, [dispatch]);

  useEffect(() => {
    setManagers(managers);
  }, [managers]);
  useEffect(() => {
    setDeps(departments);
  }, [departments]);
  const handleUpdate = e => {

    e.preventDefault();
    if (userInfo.first_name && userInfo.last_name) {
      const data = { id: team.id, userInfo };
      dispatch(updateTeam(data));
    }
    else {
      toast.warning("Please enter first name and last name", {
        toastId: "invalid first and last name teams"
      })
    }
  };
  return (
    <>
      <div
        className="card-body mx-auto row col-lg-12 pb-0 mb-5"
        style={{ paddingBottom: '0rem !important' }}
      >
        <form >
          <div className="col col-lg-12 col-sm-12 mr-2">
            <div className="row mb-0">
              <div className=" col-lg-12 col-sm-12">
                <div className="row mb-0">
                  <div className="row mb-0">
                    <div className=" col-lg-6 col-sm-12">
                      {' '}
                      <span className="font-weight-normal">
                        Email <span className="font-danger m-0">*</span>
                      </span>
                      <input
                        name="username"
                        type="email"
                        className="form-control mb-2"
                        required
                        disabled
                        placeholder="Email"
                        id="username"
                        onChange="remove_error_msg('username')"
                        value={team.email}
                      />
                    </div>
                    <div className=" col-lg-6 col-sm-12">
                      <span className="font-weight-normal">
                        Account Type <span className="font-danger m-0">*</span>
                      </span>

                      <select
                        disabled
                        required
                        className="mb-2"
                        name="role"
                        id="role"
                        onChange="remove_error_msg('role'); show_line_manager(this)"
                      >
                        <option selected>
                          {team.role === 'CliAdm' ? 'Administrator' : team.role}
                        </option>
                      </select>

                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="role_error" />
                      </div>
                    </div>
                    <div className=" col-lg-6 col-sm-12">
                      {' '}
                      <span className="font-weight-normal">
                        First Name <span className="font-danger m-0">*</span>
                      </span>
                      <input
                        name="first name"
                        type="text"
                        className="form-control mb-2"
                        required
                        placeholder="First Name"
                        id="firstname"
                        onChange={e => setUserInfo({ ...userInfo, first_name: e.target.value })}
                        value={userInfo.first_name}
                        
                      />
                    </div>
                    <div className=" col-lg-6 col-sm-12">
                      {' '}
                      <span className="font-weight-normal">
                        Last Name <span className="font-danger m-0">*</span>
                      </span>
                      <input
                        name="lastname"
                        type="text"
                        className="form-control mb-2"
                        required
                        placeholder="Last name"
                        id="lastname"
                        onChange={e => setUserInfo({ ...userInfo, last_name: e.target.value })}
                        value={userInfo.last_name}
                      />
                    </div>
                    <div className=" col-lg-6 col-sm-12">
                      <span className="font-weight-normal">
                        Department 
                      </span>

                      <select
                        
                        className="mb-2"
                        name="role"
                        id="role"
                        onChange={e =>
                          setUserInfo({ ...userInfo, profile: { department: +e.target.value } })
                        }
                      >
                        <option value="" disabled selected>
                          --SELECT--
                        </option>
                        {deps?.map(t =>
                          team?.profile?.department?.id === t.id ? (
                            <option value={t.id} selected>
                              {t.name}
                            </option>
                          ) : (
                            <option value={t.id}>{t?.name}</option>
                          ),
                        )}
                      </select>

                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="role_error" />
                      </div>
                    </div>
                    <div className=" col-lg-6 col-sm-12">
                      <span className="font-weight-normal">
                        Position 
                      </span>

                      <select
                        
                        className="mb-2"
                        name="role"
                        id="role"
                        onChange={e =>
                          setUserInfo({
                            ...userInfo,
                            manager: { ...userInfo.manager, position: +e.target.value },
                          })
                        }
                      >
                        <option value="" disabled selected>
                          --SELECT--
                        </option>
                        {mangers?.map(t =>
                          team?.manager?.position?.id === t.id ? (
                            <option value={t.id} selected>
                              {t.name}
                            </option>
                          ) : (
                            <option value={t.id}>{t?.name}</option>
                          ),
                        )}
                      </select>

                      <div className="col-lg-12 col-sm-12 m-0">
                        <span className="font-danger" id="role_error" />
                      </div>
                    </div>
                    <div className=" col-lg-6 col-sm-12">
                      {' '}
                      <span className="font-weight-normal">Hourly Rate</span>
                      <input
                        name="hourate"
                        type="number"
                        className="form-control mb-2"
                        required
                        placeholder="$0"
                        id="hourate"
                        onChange={e =>
                          setUserInfo({
                            ...userInfo,
                            manager: { ...userInfo.manager, hourly_rate: e.target.value },
                          })
                        }
                        value={userInfo.manager.hourly_rate}
                      />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                          <label htmlFor="department" className="font-weight-normal">
                            Status
                          </label>
                          <select
                            className="mb-2"
                            name="status"
                            id="status"
                            disabled={user?.role !== 'CliAdm'}
                            onChange={e => setUserInfo({ ...userInfo, status: e.target.value })}
                          >

                            <option value="" disabled defaultValue>
                              --SELECT--
                            </option>

                            {statuses?.map((stat) => (
                              <option key={stat} value={stat} selected={team?.status === stat}>
                                {stat ? 'Available' : 'Unavailable'}
                              </option>
                            ))}
                          </select>

                        </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className=" row  m-0 p-2 page-profile-footer">
        <button className="btn primary-btn" type="submit" onClick={handleUpdate}>
          Save
        </button>
      </div>
    </>
  );
}
