import React from 'react';

function DueOnDateDropdown({
    selectTheDay,
    selectTheMonth,
    handleSelectDayChange,
    handleSelectMonthChange,
    editPM
}) {

    // Function to get the day suffix
    const getDaySuffix = (day) => {
        if (day === 1 || day === 21 || day === 31) return 'st';
        if (day === 2 || day === 22) return 'nd';
        if (day === 3 || day === 23) return 'rd';
        return 'th';
    };

    // Days array with numbers
    const days = Array.from({ length: 31 }, (_, i) => {
        const day = i + 1;
        return { label: `${day}${getDaySuffix(day)}`, value: day };
    });

    console.log("days", days);
    

    // Months array
    const months = [
        { label: 'January', value: 1 },
        { label: 'February', value: 2 },
        { label: 'March', value: 3 },
        { label: 'April', value: 4 },
        { label: 'May', value: 5 },
        { label: 'June', value: 6 },
        { label: 'July', value: 7 },
        { label: 'August', value: 8 },
        { label: 'September', value: 9 },
        { label: 'October', value: 10 },
        { label: 'November', value: 11 },
        { label: 'December', value: 12 },
    ];

    return (
        <div className="mt-3">
            <label className="font-weight-normal">
                Due on the <label className="font-danger m-0">*</label>
            </label>
            <div className="grid grid-cols-2 gap-4">
                {/* Days Dropdown */}
                <select
                    disabled={editPM}
                    value={selectTheDay}
                    onChange={handleSelectDayChange}
                    className="block w-full px-4 py-2 bg-white border border-gray-300 rounded-md"
                >
                    {days.map((day) => (
                        <option key={day.value} value={day.value}>
                            {day.label}
                        </option>
                    ))}
                </select>

                {/* Months Dropdown */}
                <select
                    disabled={editPM}
                    value={selectTheMonth}
                    onChange={handleSelectMonthChange}
                    className="block w-full px-4 py-2 bg-white border border-gray-300 rounded-md"
                >
                    {months.map((month) => (
                        <option key={month.value} value={month.value}>
                            {month.label}
                        </option>
                    ))}
                </select>
            </div>
        </div>
    );
};

export default DueOnDateDropdown;
