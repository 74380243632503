
/* eslint-disable no-unused-vars */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/function-component-definition */
/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/scope */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify"
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown, faArrowTurnDown } from '@fortawesome/free-solid-svg-icons';
import trachIcoN from "../../assets/img/trashIcon.png"
import eyeIcon from "../../assets/img/eyeIcon.png"
import trachIcoNGray from "../../assets/img/trachIconGray.png"
import "./ChecklistTable.css";
import DropArea from "./DropArea";
import { createChecklist, deleteBulkChecklist, deleteChecklist, updateChecklist } from "../../redux/features/checklist/checklistSlice";
import PopUpDeleteModal from "../popUpModals/editCategory/popUpDeleteModal";
import NewModal from "../modals/NewModal";


const dragIcon = <svg width="25" height="25" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <circle cx="4" cy="8" r="1.25" fill="#777" />
  <circle cx="10" cy="8" r="1.25" fill="#777" />
  <circle cx="4" cy="12" r="1.25" fill="#777" />
  <circle cx="10" cy="12" r="1.25" fill="#777" />
  <circle cx="4" cy="16" r="1.25" fill="#777" />
  <circle cx="10" cy="16" r="1.25" fill="#777" />
</svg>

const Task = ({ index, task, onDelete, onUpdate, onDrageTask, group }) => {
  const [taskText, setTaskText] = useState(task?.name);
  const [statusType, setStatusType] = useState(task?.type)
  const dispatch = useDispatch()

  const handleUpdate = (e) => {
    if (e.key === 'Enter' && taskText) {
      onUpdate(taskText);
    }
  };

  const handelStatusClick = async (status) => {
    setStatusType(status)
    if (taskText) {
      const updatedData = {
        id: group?.id,
        userdata: {
          ...group,
          items: group?.items.map(ele => ele?.id === task?.id ? { ...ele, type: status } : ele)
        },
      };
      dispatch(updateChecklist(updatedData));
    } else {
      toast.warning('Please fill the task then try again', {
        toastId: 'CreatedEventWarning',
      });
    }
  }

  return (
    <div className="task-item" draggable onDragStart={() => onDrageTask(index)} onDragEnd={() => onDrageTask(null)}>
      {dragIcon}
      <input
        type="text"
        value={taskText}
        onChange={(e) => setTaskText(e.target.value)}
        onKeyPress={handleUpdate}
      />
      {/* <SelectStatusType
        task
        setStatusType={setStatusType}
        handelStatusClick={handelStatusClick}
        statusType={statusType}
      /> */}
      <button className="delete-task" onClick={onDelete}>
        <img style={{ width: "15px" }}
          src={trachIcoN} alt="trachIcon" />
      </button>
    </div>
  );
};

function SelectStatusType({ setStatusType, handelStatusClick, template, statusType, task }) {
  const [isShowTypes, setIsShowTypes] = useState(false)

  return <div className='Status-Type'
    style={template ? { marginRight: "0px" } : {}}
  >
    <div className="Status-Type-box"
      style={template || task ? { color: "black" } : {}}
      onClick={() => setIsShowTypes(!isShowTypes)}
    >
      <p className='mb-0'>{statusType === "typeA" ? "Status type A" : statusType === "typeB" ? "Status type B" : "Status type"}</p>
      <span style={template || task ? { color: "black" } : {}} className={isShowTypes ? "rotateIcon" : ''}
      >
        <FontAwesomeIcon icon={faAngleDown} />
      </span>
    </div>
    {isShowTypes && <div className='tck-overlay' onClick={() => setIsShowTypes(!isShowTypes)} />}
    {isShowTypes && <div className='Status-Type-results' style={template || task ? { bottom: "-122px", left: "-108px" } : {}} >
      <div className='Status-Type-results-result' onClick={() => {
        setStatusType("typeA")
        setIsShowTypes(false)
        handelStatusClick("typeA")
      }}>
        <h6>Status type A</h6>
        <span>Options (Done/Not done/Pending/On hold)</span>
      </div>
      <div className='Status-Type-results-result mt-2' onClick={(e) => {
        setStatusType("typeB")
        setIsShowTypes(false)
        handelStatusClick("typeB")
      }}>
        <h6>Status type B</h6>
        <span>Options (Passed/Failed/Pending/On hold)</span>
      </div>
    </div>
    }
  </div>
}

const ChecklistGroup = ({ getChecklistRowById, gIndex, group, onDeleteTask, onAddTask, onUpdateTask, onOpenDeletePopUp, onSelectGroup,
  onDragGroup, onDragUpdateTasks,
  setDeletedIds,
  setDeletePopUp,
  setModalBodyMsg,
  setModalTitle, }) => {
  const [groupName, setGroupName] = useState(group?.title);
  const [taskName, setTaskName] = useState('');
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeTask, setActiveTask] = useState(null);
  const [statusType, setStatusType] = useState("")
  const contentRef = useRef(null);
  const dispatch = useDispatch()

  const handleUpdateGroupTitle = async (e) => {
    if (e.key === 'Enter' && groupName) {
      const updatedData = {
        id: group?.id,
        userdata: { ...group, title: groupName },
      };
      dispatch(updateChecklist(updatedData));
    }
  };
  const handleAddTask = (e) => {
    if (e.key === 'Enter' && taskName) {
      onAddTask(group.id, taskName, group);
      setTaskName('');
    }
  };

  const handelStatusClick = async (status) => {
    if (taskName) {
      const updatedData = {
        id: group?.id,
        userdata: { ...group, items: [{ name: taskName, type: status }, ...group.items] },
      };
      dispatch(updateChecklist(updatedData));
      setTaskName("")
    } else {
      toast.warning('Please fill the task then try again', {
        toastId: 'CreatedEventWarning',
      });
    }
  }


  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const onDrageTask = (taskIndex) => {
    setActiveTask(taskIndex)
  }
  const onDropTask = (position) => {
    if (activeTask === null || activeTask === undefined) {
      return
    }
    const taskToMove = group.items[activeTask]
    const updatedTasks = group.items.filter((task, index) => index !== activeTask)

    updatedTasks.splice(position, 0, taskToMove)
    const updatedGroup = { ...group, items: updatedTasks }
    onDragUpdateTasks(updatedGroup)
    setActiveTask(null)
  }

  useEffect(() => {
    if (isExpanded) {
      contentRef.current.style.maxHeight = `${contentRef.current.scrollHeight}px`;
      contentRef.current.style.overflow = 'visible';
    } else {
      contentRef.current.style.maxHeight = '0';
      contentRef.current.style.overflow = 'hidden';
    }
  }, [isExpanded, group.items.length, activeTask]);



  const handelDeleteChecklist = (checklistId) => {
    setDeletedIds([checklistId])
    setDeletePopUp("DeleteBySelect")
    setModalBodyMsg("After delete, this checklist group and its tasks may not be available on the other feature")
    setModalTitle("Delete this checklist?")
  }

  // draggable onDragStart={() => onDragGroup(gIndex)}
  return (
    <div className="checklist-group"  >
      <div className="group-header"  >
        <input
          type="checkbox"
          checked={group.selected}
          onChange={() => onSelectGroup(group.id)}
        />
        {/* <span className="group-name">{group.title}</span> */}
        <input type="text"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          className="group-name-input"
          onKeyPress={handleUpdateGroupTitle}
        />
        <span className="task-count">{group.items.length} Tasks</span>
        <div className="group-actions">
          <button onClick={() => handelDeleteChecklist(group?.id)}>
            <img style={{ width: "15px" }}
              src={trachIcoN} alt="trachIcon" />
          </button>
          <button onClick={() => {
            getChecklistRowById(group.id)
          }}>
            <img src={eyeIcon} alt="preview" />
          </button>
          <button onClick={toggleExpand}>
            <FontAwesomeIcon icon={isExpanded ? faAngleUp : faAngleDown} />
          </button>
        </div>
      </div>
      <div className="task-list" ref={contentRef}>
        <div className="task-input">
          {dragIcon}
          <input
            type="text"
            placeholder="Add task name"
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
            onKeyPress={handleAddTask}
          />
          <span> <FontAwesomeIcon icon={faArrowTurnDown} /> Press 'Enter' to save</span>
          {/* <div style={{ display: "flex", alignItems: "center" }}>
            <SelectStatusType
              template
              setStatusType={setStatusType} 
              handelStatusClick={handelStatusClick}
            />
          </div> */}
        </div>
        {/* <DropArea onDrop={() => onDropTask(0)} /> */}
        {group.items.map((task, index) => {
          return <React.Fragment key={index}>
            <Task
              key={task?.id}
              task={task}
              group={group}
              index={index}
              onDrageTask={onDrageTask}
              onDelete={() => onDeleteTask(group.id, index, group)}
              onUpdate={(updatedTask) => onUpdateTask(group.id, index, updatedTask, group)}
            />
            {/* <DropArea onDrop={() => onDropTask(index + 1)} /> */}
          </React.Fragment>
        })}
      </div>
    </div>
  );
};

const Checklist = ({ getChecklistRowById }) => {
  const {
    checklists,
  } = useSelector(state => state.checklists);

  const [groups, setGroups] = useState(checklists?.results || []);
  const [activeGroup, setActiveGroup] = useState(null)
  const [groupName, setGroupName] = useState('');
  const [deletedIds, setDeletedIds] = useState(null)
  const [deletePopUp, setDeletePopUp] = useState("")
  const [modalbodyMsg, setModalBodyMsg] = useState("")
  const [modalTitle, setModalTitle] = useState("")


  useEffect(() => {
    setGroups(checklists?.results || [])
  }, [checklists])



  const dispatch = useDispatch()

  const handleAddTask = (groupId, taskName, group) => {
    const updatedData = {
      id: groupId,
      userdata: { ...group, items: [{ name: taskName }, ...group.items] },
    };
    dispatch(updateChecklist(updatedData));
  };

  const handleDeleteTask = async (groupId, taskIndex, group) => {
    const updatedData = {
      id: groupId,
      userdata: {
        ...group, items: group.items.filter((_, index) => index !== taskIndex)?.map(item => {
          return { ...item, key: item.id }
        })
      },
    };
    setDeletedIds(updatedData)
    setDeletePopUp("DeleteTask")
    setModalBodyMsg("After delete, this task may not be available anymore on the other feature related to checklist feature")
    setModalTitle("Delete this task?")
  };


  const handelDeleteBySelect = () => {
    const deleteIds = []
    groups.forEach(group => {
      if (group.selected) {
        deleteIds.push(group.id)
      }
    })
    // setGroups(groups.filter(item => !deleteIds.includes(item.id)))
    if (deleteIds.length > 0) {
      setDeletedIds(deleteIds)
      setDeletePopUp("DeleteBySelect")
      setModalBodyMsg("After delete, the selected checklist group and its tasks may not be available on the other feature")
      setModalTitle("Delete selected checklist?")
    } else {
      toast.warning('Please select the checklist first', {
        toastId: 'CreatedEventWarning',
      });
    }
  };

  const handleDelete = () => {
    if (deletePopUp === "DeleteBySelect") {
      dispatch(deleteBulkChecklist(
        { ids: deletedIds }
      ))
    }
    else {
      dispatch(updateChecklist(deletedIds))
    }
    setDeletePopUp("")
    setDeletedIds(null)
    setModalBodyMsg("")
    setModalTitle("")
  }



  const handleUpdateTask = async (groupId, taskIndex, updatedTask, group) => {
    const updatedData = {
      id: groupId,
      userdata: { ...group, items: group.items.map((task, index) => index === taskIndex ? { ...task, name: updatedTask, key: task?.id ? task?.id : 0 } : { ...task, key: task?.id }) },
    };
    await dispatch(updateChecklist(updatedData));

  };

  const onDragUpdateTasks = (updatedGroup) => {
    setGroups(groups.map(group =>
      group.id === updatedGroup.id
        ?
        updatedGroup
        : group
    ));
  }

  const handleAddGroup = async (e) => {
    if (e.key === 'Enter' && groupName) {
      await dispatch(createChecklist({ title: groupName, items: [] }));
      setGroupName("")
    }
  };

  // const handleDeleteGroup = async (groupId) => {
  //   await dispatch(deleteChecklist(deletedId));
  //   setPopUp(false);
  //   setDeletedId(null)
  // };

  const handleSelectAll = (e) => {
    const isSelected = e.target.checked;
    setGroups(groups.map(group => {
      return {
        ...group,
        selected: isSelected
      }
    }));
  };

  const handleSelectGroup = (groupId) => {
    setGroups(groups.map(group =>
      group.id === groupId
        ? {
          ...group,
          selected: !group.selected,
        }
        : group
    ));
  };

  const handelDragGroup = (activeDragGroup) => {
    setActiveGroup(activeDragGroup)
  }

  const onDropGroup = (position) => {
    if (activeGroup === null || activeGroup === undefined) {
      return
    }
    const groupToMove = groups[activeGroup]
    const updatedGroups = groups.filter((group, index) => index !== activeGroup)

    updatedGroups.splice(position, 0, groupToMove)
    setGroups(updatedGroups)
    setActiveGroup(null)
  }

  return (
    <>
      {deletePopUp && (
        <NewModal
          showModal={deletePopUp}
          handleClose={() => setDeletePopUp("")}
          handleCreateSave={handleDelete}
          primaryButtonText="Delete"
          modalTitle={modalTitle}
        >
          {modalbodyMsg}
        </NewModal>
      )}
      <div className="checklist pt-3" style={{ borderBottom: "1px solid #F3F4F5" }}>
        <div className="header">
          <div className="checkbox-wrapper">
            <input
              type="checkbox"
              onChange={handleSelectAll}
              checked={groups?.every(group => group?.selected) && groups?.length !== 0}
            />
            Select all
          </div>
          <div>
            <button className="new-btn mr-0" onClick={handelDeleteBySelect}>
              <img src={trachIcoNGray} alt="trachIcoNGray" />
            </button>
            {/* <button>Activate</button>
          <button>Deactivate</button> */}
          </div>
        </div>
        <div className="group-input">
          <input
            type="text"
            placeholder="Add checklist group name"
            value={groupName}
            onChange={(e) => setGroupName(e.target.value)}
            onKeyPress={handleAddGroup}
          />
          <span><FontAwesomeIcon icon={faArrowTurnDown} /> Press 'Enter' to save</span>
        </div>
        {/* <DropArea onDrop={() => onDropGroup(0)} /> */}
        {groups.map((group, index) => {
          return <div>
            <ChecklistGroup
              key={group.id}
              group={group}
              onDeleteTask={handleDeleteTask}
              onAddTask={handleAddTask}
              onUpdateTask={handleUpdateTask}
              // onOpenDeletePopUp={openDeletePopUp}
              onSelectGroup={handleSelectGroup}
              onDragGroup={handelDragGroup}
              onDragUpdateTasks={onDragUpdateTasks}
              gIndex={index}
              getChecklistRowById={getChecklistRowById}
              setDeletedIds={setDeletedIds}
              setDeletePopUp={setDeletePopUp}
              setModalBodyMsg={setModalBodyMsg}
              setModalTitle={setModalTitle}
            />
            {/* <DropArea index={index} activeGroup={activeGroup} onDrop={() => onDropGroup(index + 1)} /> */}
          </div>
        })}
      </div>
    </>
  );
};

export default Checklist;
