/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react'
import moment from 'moment'
import { useSelector } from 'react-redux'

function Activities() {
    const {
        activities
    } = useSelector(state => state.activity)
    return (
        <div id="activity-tab" role="tabpanel" aria-labelledby="custom-tabs-two-activity-tab">
            <div className="card-body">
                <div className="activity-log">
                    <h3>Activity log</h3>
                    <ul className="log-list">
                        {activities && activities?.length > 0 && activities.map(act => (
                            <li key={act.id} className="log-item">
                                <div className="log-icon">
                                    <span className="dot"></span>
                                </div>
                                <div className="log-details">
                                    <div className="log-header">
                                        Update by {act.updated_by} on {moment(act.created_at).format('DD MMMM YYYY h:mm A')}
                                    </div>
                                    <div className="log-description">{act.description}</div>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <div className="history-footer">
                    <div className="history-log-icon">
                        <span className="history-log-icon-dot"></span>
                    </div>
                        All history shown
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Activities