/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import moment from 'moment';
import React from 'react';
import { toast } from "react-toastify"
import { useDispatch, useSelector } from 'react-redux';
import {
  ApproveAndReject,
  closeWorkOrderApprovalModalDecline,
  openWorkOrderApprovalModalDecline,
  setApprovalReason,
  setCompletedDate,
  setCompletionNotes,
  setIsShowCost,
  setSignature,
  setStartedDate,
  setStatus,
} from '../../../../redux/features/workOrder/workOrderSlice';
import Signature from '../../../../components/signature/Signature';
import NewModal from '../../../../components/modals/NewModal';
import SparePart from './inv/SparePart';
import LogTime from './LogTime';

function StatusTimeLog({ id, isExternal }) {
  const [isShowSignModal, setIsShowSignModal] = React.useState(false);

  const { status, start_date, completed_date, completionNotes, signature, workOrder,
    workOrderApprovalModalDecline, approvalReason, isShowCost } = useSelector((state) => state.workOrder);
  const { logs } = useSelector(
    (state) => state.logTime,
  );

  const dispatch = useDispatch();

  const handelStatus = (e) => {
    dispatch(setStatus(e.target.value));
    dispatch(setSignature(null))
  };

  const handelShowCost = () => {
    dispatch(setIsShowCost(!isShowCost));
  };

  const handleStartedDate = (e) => {
    dispatch(setStartedDate(e.target.value));
  };
  const handleCompletedDate = (e) => {
    dispatch(setCompletedDate(e.target.value));
  };
  const handelCompletionNotes = (e) => {
    dispatch(setCompletionNotes(e.target.value));
  };
  // const handleHours = (e) => {
  //   dispatch(setHours(e.target.value));
  //   setIsError(false);
  // };
  // const handleMinutes = (e) => {
  //   dispatch(setMinutes(e.target.value));
  //   setIsError(false);
  // };

  // const handleWorkDate = (e) => {
  //   dispatch(setWorkDate(e.target.value));
  // };
  // const handelDescription = (e) => {
  //   dispatch(setDescription(e.target.value));
  // };

  // const data = {
  //   description,
  //   hours,
  //   minutes,
  //   assigned_to: assigned_to?.value,
  //   hourly_rate,
  //   work_date: moment(work_date).format(),
  // };

  // const handelEdit = (log) => {
  //   setShowEdit(log.id);
  //   dispatch(setHours(log.hours));
  //   dispatch(setMinutes(log.minutes));
  //   dispatch(setCreatedBy(log.assigned_to?.id));
  //   dispatch(setWorkDate(log.work_date));
  //   dispatch(setDescription(log.description));
  //   dispatch(setHourlyRate(log.hourly_rate));
  // };
  // const handelDelete = async (tId) => {
  //   await dispatch(deleteLog(tId));
  //   dispatch(resetLog());
  //   setShowEdit();
  // };

  // const handelCancel = () => {
  //   setShowEdit();
  //   dispatch(resetLog());
  // };

  // const handelUpdate = async (tId) => {
  //   if (work_date && assigned_to) {
  //     if (hours >= 0 && minutes >= 0 && hourly_rate >= 0) {
  //       await dispatch(updateLog({ ...data, assigned_to, id: tId }));
  //       dispatch(resetLog());
  //       setShowEdit();
  //     } else {
  //       setIsError(true);
  //     }
  //   }
  // };

  const handleAccept = async () => {
    await dispatch(ApproveAndReject({ id: workOrder?.id, approval_status: "Accepted" }))
  }
  const handleDecline = async () => {
    if (approvalReason) {
      await dispatch(ApproveAndReject({
        id: workOrder?.id,
        approval_status: "Rejected",
        approval_decline_reason: approvalReason
      }))
      await dispatch(closeWorkOrderApprovalModalDecline())
    } else {
      toast.warning('Please fill the reason and submit again', {
        toastId: 'reasonWarning',
      });
    }
  }

  const handelReason = (e) => {
    dispatch(setApprovalReason(e.target.value))
  }

  const { user } = useSelector((state) => state.user);

  const isWorkOrderValidator = workOrder?.location?.approvers?.length > 0 ? workOrder?.location?.approvers[0]?.user?.id === user?.id : false
  const isValidator = workOrder?.location?.approvers?.length > 0 ? workOrder?.location?.approvers[0]?.user?.id : false

  const { ticketinvs } = useSelector(state => state.ticketinv);
  const totalCost = logs?.reduce(
    (accumulator, currentValue) => +accumulator + +currentValue?.labor_cost,
    0,
  );
  const totHours = logs?.reduce(
    (accumulator, currentValue) => +accumulator + +currentValue?.hours,
    0,
  );
  const totalMinutes = logs?.reduce(
    (accumulator, currentValue) => +accumulator + +currentValue?.minutes,
    0,
  );
  const totalHours = Math.floor(totHours + totalMinutes / 60).toFixed();
  const min = totalMinutes % 60;
  const totalInvsCost = ticketinvs?.reduce(
    (accumulator, currentValue) => +accumulator + +currentValue?.total_cost,
    0,
  );
  const totalCostOFWO = totalCost + totalInvsCost;

  return (
    <div id="time-tab" role="tabpanel" aria-labelledby="custom-tabs-two-time-tab">
      <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 ">
        <div className=" card-header col-lg-12 px-0">
          {(isValidator &&
            (workOrder?.status === 'Complete (Pending Approval)' ||
              workOrder?.status === 'Completed' ||
              (workOrder?.status === 'Open' && workOrder?.approval_status === 'Rejected'))) && <>
              <div className="col-lg-12 col-sm-12 pt-2 wo-approval">
                <h6>Work Order Approval</h6>
                {(workOrder?.status !== 'Completed' && workOrder?.status !== 'Open') && isWorkOrderValidator && <p>This work order marked as complete but still need your approval, please kindly validate to close the work order. </p>}
                {(workOrder?.status !== 'Completed' && workOrder?.status !== 'Open') && !isWorkOrderValidator && <p> This work order has been marked as completed but is pending approval from the reviewer. Please wait for their review to close the work order </p>}
                {workOrder?.status === 'Complete (Pending Approval)'
                  && isWorkOrderValidator && <div className='flex acc-dec'>
                    <button
                      className="decline-btn mr-2"
                      type="button"
                      onClick={() => dispatch(openWorkOrderApprovalModalDecline())}
                    >
                      <span className="button__text">Decline</span>
                    </button>
                    <button
                      className="accept-btn"
                      type="button"
                      onClick={handleAccept}
                    >
                      <span className="button__text">Accept</span>
                    </button>
                  </div>}
                {workOrder?.approval_status === "Accepted" && workOrder?.status === 'Completed' &&
                  <div className='wo-approval-accepted-msg mb-2 mt-2'>
                    {isWorkOrderValidator ? "You have accept this work order submission" : "This work order submission has been accepted"} on {moment(workOrder?.approval_date).format('DD MMMM YYYY HH:mm')}
                  </div>
                }
                {workOrder?.approval_status === "Rejected" && workOrder?.status !== 'Complete (Pending Approval)' &&
                  <div className='wo-approval-declined-msg mb-2 mt-2'>
                    {isWorkOrderValidator ? "You declined this work order submission on" : "This work order submission has been declined"} on {moment(workOrder?.approval_date).format('DD MMMM YYYY HH:mm')}
                  </div>
                }
              </div>
              <hr />
            </>
          }
          <div style={{ display: 'flex', gap: '16px' }}>
            <div style={{ flex: '1' }}>
              <label className="font-weight-normal">
                Status <span className="font-danger">*</span>
              </label>
              <select
                className="form-control select-dash custom-select2 mb-2"
                onChange={handelStatus}
                value={status}
                disabled={workOrder?.status === "Declined" || !isExternal()}
              >
                <option value="" disabled selected>
                  --SELECT--
                </option>
                {(!isValidator) ? ['Open', 'In-Progress', 'On-Hold', 'Completed', "Pending", "Declined"].map(
                  (val) => {
                    if (val === "Declined" || val === "Pending") {
                      return <option key={val} value={val} disabled>
                        {val}
                      </option>
                    }
                    return <option key={val} value={val}>
                      {val}
                    </option>
                  },
                ) : ['Open', 'In-Progress', 'On-Hold', 'Complete (Pending Approval)', "Pending", "Declined"].map(
                  (val) => {
                    if (val === "Declined" || val === "Pending") {
                      return <option key={val} value={val} disabled>
                        {val}
                      </option>
                    }
                    if (val === "Complete (Pending Approval)" && status === "Completed") {
                      return <option key="Completed" value="Completed">
                        Completed
                      </option>
                    }
                    return <option key={val} value={val}>
                      {val}
                    </option>
                  },
                )}
              </select>
            </div>
            <div style={{ flex: '1' }}>
              <div className="col-12 row mb-2">
                <label className="font-weight-normal col-12">Started Date</label>
                {/* <span className="font-danger">*</span></label> */}
                <input
                  required={status === 'In-Progress'}
                  disabled={status !== 'In-Progress'}
                  type="datetime-local"
                  className="form-control"
                  value={start_date}
                  onChange={handleStartedDate}
                />
              </div>
            </div>
          </div>

          {status === 'Completed' && (
            <Signature
              signature={signature}
              setSignature={setSignature}
              isShowSignModal={isShowSignModal}
              setIsShowSignModal={setIsShowSignModal}
            />
          )}
          <div className="col-12 row mb-2">
            <label className="font-weight-normal col-12">Completed Date</label>
            {/* <span className="font-danger">*</span></label> */}
            <input
              required={status === 'Complete (Pending Approval)'}
              disabled={(status !== 'Complete (Pending Approval)' && status !== 'Completed')}
              type="datetime-local"
              className="form-control"
              value={completed_date}
              onChange={handleCompletedDate}
            />
          </div>
          {(status === 'Completed' || status === 'Complete (Pending Approval)') && (
            <div className="col-12 row mb-2 pt-2">
              <label className="font-weight-normal col-12">Completion Notes</label>
              <textarea
                className="form-control mb-2"
                name="completionNotes"
                id="completionNotes"
                placeholder="Add Completion Notes"
                onChange={handelCompletionNotes}
                value={completionNotes}
              ></textarea>
            </div>
          )}
          <hr />
          <LogTime tktId={id} />
          <hr />
          <div>
            <SparePart tktId={id} />
          </div>
          <hr />
          <div style={{ backgroundColor: '#F2F2F3', }}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '10px 18px 0px 4px',
              marginBottom: '10px'
            }}>
              <div style={{
                display: 'flex',
                gap: '8px',
                alignItems: 'center',
                paddingLeft: "10px"
              }}>
                {/* <SwitchCostTime propChecked={isShowCost} propOnChange={handelShowCost} /> */}
                <label className="weekly-frequency-switch black-switch mb-0 mr-2">
                  <input id='checkbox-toggle' type="checkbox" checked={isShowCost} onChange={handelShowCost}/>
                  <span className="weekly-frequency-slider" />
                </label>
                <div style={{ fontSize: '14px' }}>To add cost and time to work order report.</div>
              </div>
              {/* <button type='button'>
                <div style={{ fontSize: '14px', color: '#D9314A', fontWeight: "500" }}>View history log</div>
              </button> */}
            </div>
            <div className="col-12"> <hr /> </div>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '0px 18px 10px 4px',
              marginBottom: '10px',
              width: "100%"
            }}>
              <div className=" col-12  row">
                <div className="col-6 row">
                  <span className="col-5" style={{ color: '#4A4C50', fontWeight: "700" }}>Total Cost</span>
                  <span style={{ color: '#4A4C50' }} className="col-7" id="total_ticket_cost">
                    RM {totalCostOFWO.toFixed(2)}
                  </span>
                </div>
                <div className="col-6 row pr-0">
                  <span className="col-7" style={{ color: '#4A4C50', fontWeight: "700" }}>Total Time Spend</span>
                  <span className="col-5" style={{ color: '#4A4C50' }}>
                    {totalHours} hr {min} Min
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card-body p-0">
          <hr />
          <div className="col-12 row">
            <div className='mb-2'>
              <span style={{ fontWeight: "600" }}>History log</span>
            </div>
          </div>
          {logs?.length > 0 ? <div id="timelog_history">
            <div className="activity-log">
              <ul className='log-list'>
                {logs &&
                  logs.map((log) => {
                    return (
                      <li key={log.id} className="log-item" >
                        <div className="log-icon">
                          <span className="dot"></span>
                        </div>
                        <div className='log-details'>
                          <div className="log-header">
                            Update by {log.created_by?.first_name} {log.created_by?.last_name} on {moment(log.work_date).format('DD MMMM YYYY h:mm A')}
                          </div>
                          <div className="log-description">
                            <div style={{ display: "flex", alignItems: "center" }}>
                              <div className='mr-5'>Time: <span style={{ fontWeight: "700" }}>{log.hours} h : {log.minutes} m</span></div>
                              <div>Cost: <span style={{ fontWeight: "700" }}>RM {log.labor_cost} </span></div>
                            </div>
                            <div>
                              {log.description}
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
              {logs?.length > 0 && <div className="history-footer">
                <div className="history-log-icon">
                  <span className="history-log-icon-dot"></span>
                </div>
                All history shown
              </div>}
            </div>
          </div> :
          <div style={{textAlign: "center", paddingBottom: "20px"}}>
            No logs yet
          </div>
          }
        </div>
      </div>
      {workOrderApprovalModalDecline && (
        <div className='modal-wrapper'>
          <NewModal
            showModal={workOrderApprovalModalDecline}
            handleClose={() => dispatch(closeWorkOrderApprovalModalDecline())}
            handleCreateSave={handleDecline}
            primaryButtonText="Decline"
            modalTitle="Decline this work order submission?"
          >
            <div className="col-lg-12 col-sm-12" onClick={(e) => e.stopPropagation()}>
              <textarea
                className="form-control mb-2"
                name="description"
                id="meter_description"
                placeholder="Please state your reason*"
                onChange={handelReason}
                value={approvalReason}
              ></textarea>
            </div>
            <div className="col-lg-12 col-sm-12">
              This message will be included in the assigned person's notification for their review.
            </div>
          </NewModal>
        </div>
      )}
    </div>
  );
}

export default StatusTimeLog;





{/* {log.id === showEdit && (
                      <div className="col-12 p-1 mb-2">
                        <div>
                          <input
                            type="number"
                            min="0"
                            className="form-control "
                            style={{ width: '4em', display: 'inline' }}
                            placeholder="Hours Done"
                            defaultValue={hours || log.hours}
                            onChange={handleHours}
                          />{' '}
                          <strong className="red">hr</strong>{' '}
                          <input
                            type="number"
                            min="0"
                            className="form-control "
                            style={{ width: '4em', display: 'inline' }}
                            placeholder="Minutes Done"
                            defaultValue={minutes || log.minutes}
                            onChange={handleMinutes}
                          />{' '}
                          <strong className="red">Min</strong> to{' '}
                          <strong className="red"> {log?.assigned_to?.name} </strong> on
                          <input
                            type="datetime-local"
                            className="form-control "
                            placeholder="Work Date"
                            onChange={handleWorkDate}
                            defaultValue={
                              moment(log?.work_date).format('YYYY-MM-DDTHH:mm') ||
                              moment(log.work_date).format('YYYY-MM-DDTHH:mm')
                            }
                            style={{ width: '10em', display: 'inline' }}
                          />
                        </div>
                        <div className="p-2">
                          <textarea
                            className="form-control mb-2"
                            placeholder="Task Description"
                            onChange={handelDescription}
                            defaultValue={description || log.description}
                          ></textarea>
                          {isError && <span className="red">Please Add A Positive Numbers</span>}
                        </div>
                        <div className="btn">
                          <button
                            onClick={() => handelDelete(log.id)}
                            type="button"
                            data-toggle="modal"
                            data-target="#modal-timelog"
                            title="Delete Time Log"
                            className="fas fa-trash float-right mx-2 my-1 red"
                          ></button>
                          <button
                            onClick={handelCancel}
                            type="button"
                            title="Cancel"
                            id="log-view-btn-history.id"
                            className=" ml-auto float-right"
                          >
                            <b>Cancel</b>
                          </button>

                          <button
                            type="button"
                            title="Save"
                            className=" ml-auto float-right"
                            onClick={() => handelUpdate(log.id)}
                          >
                            <i className="fas fa-check mx-2 my-1 red"></i>
                          </button>
                        </div>
                      </div>
                    )} */}




// {!showEdit && user?.id === log?.created_by?.id && (
//   <button
//     type="button"
//     title="Edit"
//     className=" ml-auto float-right secondary-btn btn"
//     onClick={() => handelEdit(log)}
//   >
//     <b>Edit</b>
//   </button>
// )}