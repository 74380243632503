import React from 'react';

function WeeklyTicketSwitches({selectedDays, toggleDay, editPM}) {
    console.log("selectedDays", selectedDays);

    return (
        <div className="mt-3">
            <label htmlFor="weekly-frequency" className="font-weight-normal mb-2">
                Create ticket on day <label className="font-danger m-0">*</label>
            </label>
            <div className='grid grid-cols-2 gap-4'>
            {selectedDays.map((day) => (
                <div key={day.day} className="toggle-switch">
                    <label className="weekly-frequency-switch">
                        <input type="checkbox" disabled={editPM} checked={day.isChoosen} onChange={() => toggleDay(day.day)} />
                        <span className="weekly-frequency-slider" />
                    </label>
                    <span className="weekly-frequency-label">{day.day}</span>
                </div>
            ))}
        </div>
        </div>
    );
};

export default WeeklyTicketSwitches;
