import React, { useEffect, useState } from 'react';
import { Tab, Tabs, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setFilterAdvanceStatus, setFilterStatus } from '../../../redux/features/workOrder/workOrderSlice';
import './WOAccDeclineTabs.scss'

function WOAcceptAndDeclineTabs() {
  const { workOrdrSummary, filterStatus, filterAdvanceStatus } = useSelector(state => state.workOrder);
  const [activeKey, setActiveKey] = useState('All');
  const dispatch = useDispatch();

  useEffect(() => {
    if (filterStatus?.label) {
      if (filterStatus?.label !== 'Pending' && filterStatus?.label !== 'Accepted' && filterStatus?.label !== 'Declined') {
        setActiveKey('All');
      } else {
        setActiveKey(filterStatus?.label);
      }
    }
  }, [filterStatus]);

  const renderTooltip = (props, text) => (
    <Tooltip id="button-tooltip" {...props} className="custom-tooltip">
      {text}
    </Tooltip>
  );

  const tooltipIcon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.99967 10.6663V7.99967M7.99967 5.33301H8.00634M14.6663 7.99967C14.6663 11.6816 11.6816 14.6663 7.99967 14.6663C4.31778 14.6663 1.33301 11.6816 1.33301 7.99967C1.33301 4.31778 4.31778 1.33301 7.99967 1.33301C11.6816 1.33301 14.6663 4.31778 14.6663 7.99967Z"
    stroke="#999999"
    strokeWidth="1.5"
    strokeLinecap="round"
    strokeLinejoin="round" />
</svg>


const handelStatus = () => {
  const updatedStatus = filterAdvanceStatus.map(ele => {
    return { ...ele, selected: false }
  })
  dispatch(setFilterAdvanceStatus(updatedStatus))
}

  return (
    <div className="mb-3 mt-3">
      <Tabs
        activeKey={activeKey}
        id="wo-tabs"
        className="nav nav-tabs custom_tabs"
        onSelect={k => {
          console.log("k", k);
          
          setActiveKey(k);
          dispatch(setFilterStatus({ label: k }));
          if (k === "Pending" || "Declined" || "All") {
            handelStatus()
          }
        }}
      >
        <Tab
          eventKey="All"
          title={
            <>
              <span className="tab-title">All ( {workOrdrSummary?.total_tickets} )</span>
              <OverlayTrigger placement="top" overlay={renderTooltip(null, 'Displays all items, regardless of their current status, providing a comprehensive overview.')}>
                {tooltipIcon}
              </OverlayTrigger>
            </>
          }
        />
        <Tab
          eventKey="Pending"
          title={
            <>
              <span className="tab-title">New request ( {workOrdrSummary?.new_requests} )</span>
              <OverlayTrigger placement="top" overlay={renderTooltip(null, 'Shows newly submitted items and are awaiting further action, helping to identify “pending” tasks.')}>
              {tooltipIcon}
              </OverlayTrigger>
            </>
          }
        />
        <Tab
          eventKey="Accepted"
          title={
            <>
              <span className="tab-title">Accepted ( {workOrdrSummary?.accepted} )</span>
              <OverlayTrigger placement="top" overlay={renderTooltip(null, 'Lists items that have been reviewed and approved, offering a view of “opened” tasks or requests.')}>
              {tooltipIcon}
              </OverlayTrigger>
            </>
          }
        />
        <Tab
          eventKey="Declined"
          title={
            <>
              <span className="tab-title">Declined ( {workOrdrSummary?.declined} )</span>
              <OverlayTrigger placement="top" overlay={renderTooltip(null, 'Includes items that have been reviewed and declined, allowing users to track unsuccessful requests.')}>
              {tooltipIcon}
              </OverlayTrigger>
            </>
          }
        />
      </Tabs>
    </div>
  );
}

export default WOAcceptAndDeclineTabs;
