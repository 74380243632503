/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/self-closing-comp */
/* eslint-disable array-callback-return */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-unused-expressions */
/* eslint-disable arrow-body-style */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable react/no-array-index-key */
/* eslint-disable consistent-return */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/button-has-type */
/* eslint-disable no-unused-vars */

import React, { useEffect, useRef, useState } from "react";
import moment from 'moment';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';
import ReactCropper from "react-cropper";
import { faTrashCan, faGaugeHigh, faFile, faX, faPenToSquare, faCropSimple } from '@fortawesome/free-solid-svg-icons';
import send from "../../../../assets/img/send-svg.svg";
import {
  setAssignedTo,
  setCategory,
  setPriority,
  setDescription,
  setDueFinish,
  setTitle,
  setFiles,
  //   setTicketType,
  // getDueDate,
  setWorkOrderType,
  setIsShowCost,
  setMeterId,
  setCurrentReading,
  setUploadMeterAttachment,
  getDueDate,
  addAttachmentTicket,
  editAttachmentTicket,
  deleteAttachmentTicket,
} from '../../../../redux/features/workOrder/workOrderSlice';
import {
  SelectAsset,
  SelectLocation,
  SelectMeter,
  SelectPersons,
  SelectSite,
} from '../../../../components/select/select';
import formatNumber from '../../../../utils/formatNumber';
import { getMeterById, getMetersByPagination, openMeterModalUpdate, setTab } from '../../../../redux/features/meter/meterSlice';
import { getMeterCategoryByAuthentication } from '../../../../redux/features/meterCategory/meterCategorySlice';
import ExclamationIcon from '../../../../components/layout/components/icons/ExclamationIcon';
// import { selectActions } from '../../../../redux/features/selectOptions/selectSlice';
import "./EditWO.scss"
import StatusSwitch from "../../StatusSwitch";



function Overview({ isTechCanEdit }) {


  const dispatch = useDispatch();
  const workOrderCategory = useSelector(state => state.workOrderCategory?.workOrderCategories);
  const maintenanceTypes = useSelector(state => state.maintenanceType?.maintenanceTypes);
  const maintenanceCategory = useSelector(state => state.maintenanceCategory?.maintenanceCategories)

  const teams = useSelector(state => state.teams?.teams?.results);
  const {
    openFrom,
    title,
    description,
    priority,
    category,
    assigned_to,
    due_finish,
    attachments,
    files,
    workOrder,
    fixedDate,
    workOrderType,
    isShowCost,
    meterId,
    currentReading,
    rquestor,
    uploadMeterAttachment,
    isSLAEnabled
  } = useSelector(state => state.workOrder);
  const { user } = useSelector(state => state.user);
  const { selectedSite, assetInfo } = useSelector(state => state.selectOptions);
  const { locations, sublocations } = useSelector(state => state.location);
  const assetListSearch = useSelector(state => state.asset?.assetListSearch);
  const { meters } = useSelector(state => state.meter);

  const [image, setImage] = useState(null);
  const [caption, setCaption] = useState("");
  const [preview, setPreview] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalClass, setModalClass] = useState("");
  const [uploads, setUploads] = useState([]);
  const [editIndex, setEditIndex] = useState(null); // Index of the item being edited
  const [attachmentId, setAttachmentId] = useState(null); // Index of the item being edited


  const [cropData, setCropData] = useState('#');
  const [crop, setCrop] = useState(false);
  const [originalImage, setOriginalImage] = useState(false);
  const cropperRef = useRef(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file)
      reader.onload = (event) => {
        const imageURL = event.target.result
        const photo = document.createElement("img")
        photo.src = imageURL
        photo.onload = (imageEvent) => {
          const canvas = document.createElement("canvas")
          const WIDTH = 800
          const ratio = WIDTH / imageEvent.target.width
          canvas.width = WIDTH
          canvas.height = imageEvent.target.height * ratio
          const context = canvas.getContext("2d")
          context.drawImage(photo, 0, 0, canvas.width, canvas.height)
          const newImageURL = context.canvas.toDataURL("image/jpeg", 100)
          context.canvas.toBlob((blob) => {
            setImage(blob);
          })
          setPreview(newImageURL);
          setOriginalImage(newImageURL);
        }
        setModalClass("modal--open");
        setModalIsOpen(true);
      };
      setEditIndex(null); // Reset edit index
    }
  };


  const handleEditImageChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file)
      reader.onload = (event) => {
        const imageURL = event.target.result
        const photo = document.createElement("img")
        photo.src = imageURL
        photo.onload = (imageEvent) => {
          const canvas = document.createElement("canvas")
          const WIDTH = 800
          const ratio = WIDTH / imageEvent.target.width
          canvas.width = WIDTH
          canvas.height = imageEvent.target.height * ratio
          const context = canvas.getContext("2d")
          context.drawImage(photo, 0, 0, canvas.width, canvas.height)
          const newImageURL = context.canvas.toDataURL("image/jpeg", 100)
          context.canvas.toBlob((blob) => {
            setImage(blob);
          })
          setPreview(newImageURL);
          setOriginalImage(newImageURL);
        }
      };
    }
  };

  const handleCaptionChange = (e) => {
    setCaption(e.target.value);
  };

  const cropImage = () => {
    setCrop(true);
  };

  const getCropData = (e) => {
    e.preventDefault();
    if (cropperRef.current?.cropper !== 'undefined') {
      setCropData(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
      cropperRef.current?.cropper.getCroppedCanvas().toBlob((blob) => {
        setImage(blob)
        setPreview(cropperRef.current?.cropper.getCroppedCanvas().toDataURL())
      }, 'image/jpeg');
      setCrop(false);
    }
  };

  const handleSubmit = () => {
    if (editIndex !== null) {
      // Update existing upload
      const updatedUploads = attachments?.map((upload, index) =>
        index === editIndex
          ? {
            ...upload,
            image: image ? image : null,
            file: image ? image.name : upload?.name,
            caption,
            preview
          }
          : upload
      );
      setUploads(updatedUploads);
      // handelSubmit(e, [updatedUploads[editIndex]])

      const formData = new FormData()
      if (image) {
        formData.append("photo", image, `${new Date().toISOString()}.png`)
      }
      formData.append("caption", caption)
      dispatch(editAttachmentTicket({ ticketId: workOrder?.id, attachmentId, formData }))
    } else {
      // Create a new upload
      const newUpload = {
        image,
        file: image.name,
        caption,
        preview,
      };
      setUploads([...uploads, newUpload]);
      // handelSubmit(e, [newUpload])
      const formData = new FormData()
      formData.append("photo", newUpload.image, `${new Date().toISOString()}.png`)
      formData.append("caption", newUpload.caption)
      console.log(image);

      dispatch(addAttachmentTicket({ ticketId: workOrder?.id, formData }))
    }
    // Close the modal with animation
    setModalClass("modal--close");
    setTimeout(() => {
      setModalIsOpen(false);
      // Reset the form fields
      setImage(null);
      setCaption("");
      setPreview("");
      setEditIndex(null); // Reset edit index
    }, 300); // Allow time for fade out
  };

  useEffect(() => {
    dispatch(setFiles(uploads));
  }, [uploads])


  const handleEdit = (index) => {
    const upload = attachments[index];
    // setImage(attachments[index]); // Clear image input
    setCaption(upload?.caption || "");
    setPreview(upload?.photo);
    // setImage(upload?.photo);
    // setOriginalImage(upload?.photo);
    setAttachmentId(upload?.id)
    setModalClass("modal--open");
    setModalIsOpen(true);
    setEditIndex(index);
  };

  function ImageIcon() {
    return <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 4C3 2.89543 3.89543 2 5 2H19C20.1046 2 21 2.89543 21 4V20C21 21.1046 20.1046 22 19 22H5C3.89543 22 3 21.1046 3 20V4ZM19 4H5V20H19V4ZM10 14.8284L12.5858 17.4142C12.9763 17.8047 13.6095 17.8047 14 17.4142L19 12.4142V15H21V9H15V11H17.5858L13.7071 14.8787L11.1213 12.2929C10.7308 11.9024 10.0976 11.9024 9.70711 12.2929L7 15V17H9V14.8284ZM9 7C9 5.89543 9.89543 5 11 5C12.1046 5 13 5.89543 13 7C13 8.10457 12.1046 9 11 9C9.89543 9 9 8.10457 9 7ZM11 7C10.4477 7 10 7.44772 10 8C10 8.55228 10.4477 9 11 9C11.5523 9 12 8.55228 12 8C12 7.44772 11.5523 7 11 7Z"
        fill="currentColor"
      />
    </svg>
  }

  const allSubLocations = [];
  const handelTitle = e => {
    dispatch(setTitle(e.target.value));
  };
  const handelDescription = e => {
    dispatch(setDescription(e.target.value));
  };
  const handelCategory = e => {
    dispatch(setCategory(e.target.value));
  };
  const handelMeterAttachment = e => {
    dispatch(setUploadMeterAttachment(e.target.files[0]));
  };
  const handelCurrentReading = e => {
    const input = e.target;
    const { value } = input;
    const formattedValue = formatNumber(value)[0];
    dispatch(setCurrentReading(formattedValue));
  };
  const handelDueFinish = e => {
    dispatch(setDueFinish(e.target.value));
  };

  const handelShowCost = () => {
    dispatch(setIsShowCost(!isShowCost));
  };
  const openMeterTickect = async () => {
    await dispatch(getMeterById(workOrder?.meter?.id));
    dispatch(
      getMetersByPagination({ rowPerPage: 10, currentPage: 1, SearchVal: workOrder?.meter?.title }),
    );
    dispatch(openMeterModalUpdate());
    dispatch(setTab('service-tab'));
    dispatch(getMeterCategoryByAuthentication())
  };

  const workOrderTypes = useSelector(state => state.workOrderType?.workOrderTypes);
  const handelPriority = e => {
    dispatch(setPriority(e.target.value));
    if (openFrom !== "Maintenance") {
      dispatch(getDueDate({ priority: e.target.value, date: fixedDate }));
    }
  };

  const handelWorkOrderType = e => {
    dispatch(setWorkOrderType(e.target.value));
  };
  const handelAttachments = e => {
    dispatch(setFiles([{ file: e.target.files[0] }]));
  };
  const deleteAttachment = async attachId => {
    await dispatch(deleteAttachmentTicket(attachId));
    // dispatch(getAttachment(attachments.filter(attach => attach.id !== attachId)))
  };
  const { logs } = useSelector(state => state.logTime);
  const { ticketinvs } = useSelector(state => state.ticketinv);
  const totalCost = logs?.reduce(
    (accumulator, currentValue) => +accumulator + +currentValue?.labor_cost,
    0,
  );
  const totHours = logs?.reduce(
    (accumulator, currentValue) => +accumulator + +currentValue?.hours,
    0,
  );
  const totalMinutes = logs?.reduce(
    (accumulator, currentValue) => +accumulator + +currentValue?.minutes,
    0,
  );
  const totalHours = Math.floor(totHours + totalMinutes / 60).toFixed();
  const min = totalMinutes % 60;
  const totalInvsCost = ticketinvs?.reduce(
    (accumulator, currentValue) => +accumulator + +currentValue?.total_cost,
    0,
  );
  const totalCostOFWO = totalCost + totalInvsCost;
  return (
    <div
      className="tab-pane fade active show edit-wo-overview"
      id="service-tab-edit"
      role="tabpanel"
      aria-labelledby="custom-tabs-two-service-tab"
    >
      <div className="card-body row">
        {workOrder?.status === "Declined" && <div className="col-lg-12 col-sm-12 decline-msg">
          <h4> <span className='mr-1'><ExclamationIcon fillColor="primary" /></span> This request was declined</h4>
          <h6>The reason of rejection:</h6>
          <p>{workOrder?.decline_reason}</p>
        </div>}
        <div className="col-lg-12 col-sm-12">
          <label className="font-weight-normal gcolor">
            Title
            <label className="font-danger m-0">*</label>
          </label>
          <input
            type="text"
            className="form-control mb-2 font-weight-normal"
            name="meter_name"
            id="meter_name"
            onChange={handelTitle}
            value={title}
            required
            disabled={isTechCanEdit}
          />
        </div>
        <div className="col-lg-12 col-sm-12">
          <label className="font-weight-normal gcolor">Description</label>
          <textarea
            className="form-control mb-2"
            name="description"
            id="meter_description"
            placeholder="Add description"
            onChange={handelDescription}
            value={description}
            disabled={isTechCanEdit}
          ></textarea>
        </div>

        {/* Location and Sublocation */}
        <div className="col-lg-12 col-sm-12 pt-3">
          <div id="ifLocation" className="col-12 p-0 loc-subloc-wrapper">
            <div style={{ flex: '1' }}>
              <SelectSite locations={locations} assetInfo={assetInfo} width="100%" noStar />
            </div>
            {selectedSite && (
              <div className="col-12 p-0" id="building_show" style={{ flex: '1' }}>
                <SelectLocation
                  assetInfo={assetInfo}
                  subLocation={sublocations?.results}
                  allSubLocations={allSubLocations}
                  width="100%"
                  noStar
                />
              </div>
            )}
          </div>
        </div>

        {/* Asset and Meter */}
        <div className="asset-meter-wrapper">
          <div className="col-lg-12 col-sm-12 pt-3" style={{ flex: '1' }}>
            <div id="ifAsset" className="col-12 p-0">
              <label className="font-weight-normal">Assets</label>
              <SelectAsset width="100%" setAssignedTo={setAssignedTo} assetData={assetListSearch} woAsset={workOrder?.asset} />
            </div>
          </div>
          <div className="col-lg-12 col-sm-12 pt-3" style={{ flex: '1' }}>
            <label className="font-weight-normal">
              Meter
            </label>
            <div className="col-12 p-0">
              <SelectMeter
                meters={meters?.results}
                meterId={meterId}
                setMeterId={setMeterId}
                setAssignedTo={setAssignedTo}
                locations={locations}
              />
            </div>
          </div>
        </div>

        <div className="col-lg-12 col-sm-12 pt-3">
          <label className="font-weight-normal">
            Assigned to <span className="font-danger">*</span>
          </label>
          <div className="col-12 p-0">
            <SelectPersons
              teams={teams}
              assignedTo={assigned_to}
              onEdit
              setAssignedTo={setAssignedTo}
            />
          </div>
        </div>

        {/* Category and subcategory */}
        <div className="cat-subcat-wrapper">
          <div className="col-lg-12 col-sm-12 pt-3" style={{ flex: '1' }}>
            <label className="font-weight-normal">
              Category<label className="font-danger m-0">*</label>{' '}
            </label>
            <select
              className="form-control select-dash custom-select2 mb-2"
              name="category"
              id="category2"
              value={category}
              onChange={handelCategory}
              disabled={isTechCanEdit}
            >
              <option value="" disabled>
                --SELECT--
              </option>
              {openFrom !== "Maintenance" ? workOrderCategory && workOrderCategory.length > 0 &&
                workOrderCategory.map(cat => (
                  <option key={cat.name} value={cat.name}>
                    {cat.name}
                  </option>
                )) : maintenanceCategory && maintenanceCategory.length > 0
              && maintenanceCategory.map(cat => (
                <option
                  value={cat.name}
                  key={cat.id}
                >{cat.name}</option>
              ))}
            </select>
          </div>
          <div className="col-lg-12 col-sm-12 pt-3" style={{ flex: '1' }}>
            <label className="font-weight-normal">Sub Category</label>
            <select
              className="form-control select-dash custom-select2 mb-2"
              name="category"
              id="category2"
              onChange={handelWorkOrderType}
              value={workOrderType}
            >
              <option value="" disabled selected>
                --SELECT--
              </option>

              {openFrom !== "Maintenance" ? workOrderTypes &&
                workOrderTypes.length > 0 &&
                workOrderTypes.map(type => (
                  <option key={type.name} value={type.name}>
                    {type.name}
                  </option>
                )) : maintenanceTypes && maintenanceTypes.length > 0
              && maintenanceTypes.map(type => (
                <option
                  value={type.name}
                  key={type.id}
                >{type.name}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Priority and Due Date */}
        <div className="pr-due-wrapper">
          <div className="col-lg-12 col-sm-12 pt-3" style={{ flex: '1' }}>
            <div style={{ display: 'flex', justifyContent: "space-between", alignItems: 'center' }}>
              <label className="font-weight-normal ">Priority</label>
              {openFrom !== "Maintenance" && <div style={{ display: 'flex', alignItems: 'baseline' }}>
                <label className="weekly-frequency-switch black-switch mr-3">
                  <input id='checkbox-toggle' type="checkbox" checked={isSLAEnabled} disabled />
                  <span className="weekly-frequency-slider" />
                </label>
                <div>SLA</div>
              </div>
              }
            </div>
            <select
              className="form-control select-dash custom-select2 mb-2"
              name="priority2"
              id="priority2"
              value={priority}
              onChange={handelPriority}
              disabled={isTechCanEdit}
            >
              <option value="" disabled selected>
                --SELECT--
              </option>
              {openFrom !== "Maintenance" ? ['Critical', 'High', 'Medium', 'Low'].map(val => (
                <option key={val} value={val}>
                  {val}
                </option>
              )) : ['High', 'Medium', 'Low'].map(val => (
                <option key={val} value={val}>
                  {val}
                </option>
              ))}
            </select>
          </div>
          <div className=" col-lg-6 col-md-6 col-sm-12 pt-3" style={{ flex: '1', paddingTop: '1px' }}>
            <label className="font-weight-normal">
              Due Date
              <label className="font-danger m-0">*</label>
            </label>
            <input
              type="datetime-local"
              className="form-control mb-2"
              name="service_date"
              id="meter_service_date"
              placeholder="End Date"
              onChange={handelDueFinish}
              value={due_finish}
              required
              disabled={isTechCanEdit}
              style={{ marginTop: '6px' }}
            />
          </div>
        </div>

        <div className="col-lg-12 col-sm-12 pt-3">
          <label className="font-weight-normal">
            Requestor Detail
          </label>
          <input
            name="requestor"
            type="email"
            className="form-control mb-2"
            placeholder="Requestor"
            value={rquestor}
            disabled
          />
          <div className="col-lg-12 col-sm-12 m-0">
            <span className="font-danger" id="meter_error"></span>
          </div>
        </div>

        <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 pb-2 pt-3">
          <div className="card-header m-0 mb-2 p-0 gcolor">
            <span>Attachments</span>
          </div>
          <div style={{ background: '#f8f8f8' }}>
            <input
              type="file"
              id="file-input"
              onChange={handleImageChange}
              multiple
              name="file-input"
              accept="image/*"
            />
            <label id="file-input-label" htmlFor="file-input">
              Choose Files
            </label>
          </div>
          <div>
            {modalIsOpen && <div className={`the-modal ${modalClass}`}>
              <div className="the-modal-header">
                {image !== null && <button
                  type="button"
                  onClick={cropImage}
                  className="close-button mr-4"
                  title="Crop"
                >
                  <FontAwesomeIcon icon={faCropSimple} />
                </button>
                }
                <button
                  type="button"
                  onClick={() => {
                    setModalClass("modal--close");
                    setModalIsOpen(false) // Allow time for fade out
                    setImage(null);
                    setCaption("");
                    setPreview("");
                    setEditIndex(null); // Reset edit index
                  }}
                  className="close-button"
                  title="Close"
                >
                  <FontAwesomeIcon icon={faX} />
                  {/* <FontAwesomeIcon icon={faTimes} /> */}
                </button>
              </div>
              <div className="preview-container">
                {preview && (
                  <img src={preview} alt="Preview" className="image-preview" />
                )}
                <div className="w-100">
                  <div className="caption-container">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleEditImageChange}
                      id="editFileInput"
                      className="the-file-input"
                    />
                    <label htmlFor="editFileInput" className="the-file-input-label">
                      <ImageIcon />
                    </label>
                    <textarea
                      // type="text"
                      placeholder="Add a caption..."
                      value={caption}
                      onChange={handleCaptionChange}
                      className="caption-input"
                    />
                    <button type="button" onClick={handleSubmit} className="send-button">
                    <img src={send} alt="send-icon" />

                  </button>
                  </div>
                </div>
              </div>
            </div>
            }
            {crop ? (
              <div className="wrapper-new-croper">
                <div className="new-croper">
                  <ReactCropper
                    style={{ height: '100%', width: '100%' }}
                    initialAspectRatio={1}
                    className="row align-items-center my-auto col-lg-12 col-md-12 col-sm-12 croper"
                    preview=".img-preview"
                    src={originalImage}
                    ref={cropperRef}
                    viewMode={0.5}
                    guides
                    minCropBoxHeight={10}
                    minCropBoxWidth={10}
                    background
                    responsive
                    checkOrientation={false}
                  />
                  <div
                    className="box"
                  // style={{ width: "50%", float: "right", height: "300px" }}
                  >
                    <div className="buttons_crop">
                      <button onClick={() => setCrop(false)} type="button" className="btn btn-danger">
                        Cancel
                      </button>

                      <button onClick={getCropData} type="button" className="btn btn-success">
                        Crop Image
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            {attachments?.length > 0 && <div className="uploads-container">
              {attachments?.length > 0 && attachments.map((upload, index) => (
                <div
                  key={index}
                  className="upload-item"
                >
                  <img src={upload?.photo} className="upload-file" alt="upload-file" />
                  <p className="upload-caption" title={upload?.caption}>
                    {upload.caption?.length > 45 ? `${upload.caption?.slice(0, 45)}...` : upload?.caption}
                  </p>
                  <button
                    type="button"
                    onClick={() => handleEdit(index)}
                    className="edit-upload-item"
                  >
                    <span
                      className="text font-danger"
                      data-toggle="modal"
                      data-target="#modal-attachment"
                      title='Edit Attachment'
                    >
                      <FontAwesomeIcon icon={faPenToSquare} />
                    </span>
                  </button>
                  {!isTechCanEdit &&
                    <button
                      type="button"
                      onClick={() => deleteAttachment({ ticketId: workOrder?.id, attachmentId: upload.id })}
                      className="delete-upload-item"
                    >
                      <span
                        className="text font-danger"
                        data-toggle="modal"
                        data-target="#modal-attachment"
                        title='Delete Attachment'
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </span>
                    </button>}
                </div>
              ))}
            </div>}
          </div>
        </div>

        {/* <div className="col-lg-12 col-sm-12 col-xs-12 col-md-12 pb-2 pt-3">
          <div className="card-header m-0 mb-2 p-0 gcolor">
            <span>Attachments</span>
          </div>
          <div className="card-body m-0 p-0 col-12">
            <div style={{ background: '#f8f8f8' }}>
              <input
                type="file"
                id="file-input"
                onChange={handelAttachments}
                multiple
                name="file-input"
              />
              <label id="file-input-label" htmlFor="file-input">
                Choose Files
              </label>
            </div>
            {files?.length > 0 && (
              <p style={{ marginBottom: '5px', fontSize: '20px' }}>Files Choosen</p>
            )}
            <ul>
              {files?.map((file, index) => (
                <span style={{ marginRight: '10px' }} key={index}>
                  {file?.file?.name}
                </span>
              ))}
            </ul>
            <div className="row">
              {attachments?.length > 0 &&
                attachments?.map(file => (
                  <div className="container col-2 m-0 my-2 text-center" title={file.name}>
                    <button
                      type="button"
                      className="image p-2 photo"
                      style={{ height: '100%', width: '100%' }}
                    >
                      {file?.type === 'photo' ? (
                        <a target="_blank" rel="noopener noreferrer" href={file.photo}>
                          <img
                            id="myImg"
                            alt="file"
                            style={{ height: '100%', width: '100%' }}
                            src={file.photo}
                            data-toggle="modal"
                            data-target="#myModal"
                          />
                        </a>
                      ) : (
                        <a
                          style={{ fontSize: '12px' }}
                          target="_blank"
                          rel="noopener noreferrer"
                          href={file.file}
                          title={file.name}
                        >
                          {file.name.slice(0, 10)}
                        </a>
                      )}
                    </button>
                    <button
                      disabled={isTechCanEdit}
                      type="button"
                      onClick={() => deleteAttachment(file.id)}
                      className="middle"
                      style={{ opacity: 1 }}
                    >
                      <span
                        className="text font-danger"
                        data-toggle="modal"
                        data-target="#modal-attachment"
                        title={isTechCanEdit ? "You Can't Delete it" : 'Delete Attachment'}
                      >
                        <FontAwesomeIcon icon={faTrashCan} />
                      </span>
                    </button>
                  </div>
                ))}
            </div>
          </div>
        </div> */}

        {workOrder?.asset && (
          <div
            className="meter-card col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-3"
            style={{ borderRadius: '8px !important' }}
          >
            <div className="card-header m-0 p-0 gcolor">
              <span>Asset Details</span>
            </div>
            <div className="card-body row p-2">
              <div className="col-12 medium-title mb-3">
                {user?.role !== 'Requester' ? (
                  <Link to={`/assets/${workOrder?.asset?.uuid}`} title="More Asset Details">
                    <span className="col-4"> {workOrder?.asset?.asset_number}</span>
                  </Link>
                ) : (
                  <span>{workOrder?.asset?.asset_number}</span>
                )}
                <span className="col-8"> {workOrder?.asset?.name}</span>
              </div>

              <div className="col-6">
                <span className="gcolor col-12">Type </span>
                <span className=""> {workOrder?.asset?.asset_type}</span>
              </div>
              <div className="col-6">
                <span className="gcolor col-12">Category</span>
                <span className=""> {workOrder?.asset?.category}</span>
              </div>
              <div className="col-12 mt-3">
                <span className="gcolor col-12">Location</span>
                <span className="">{workOrder?.asset?.location}</span>
              </div>
            </div>
          </div>
        )}

        {workOrder?.location !== null && (
          <div className="meter-card col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-3 location-details p-3">
            <div className="card-header m-0 p-0 gcolor">
              <span>Location details</span>
            </div>
            <div className="m-0 pt-2 gcolor" id="L-view">
              <h6 className="title" style={{ color: 'red', paddingLeft: '5px' }}>
                Location
                <div style={{ color: 'black' }}>{workOrder?.location?.site}</div>
              </h6>
              {workOrder?.sub_location !== null && (
                <ul className="wtree">
                  {workOrder?.sub_location?.building && (
                    <ul>
                      <li>
                        <span style={{ marginRight: '50%' }}>
                          Building
                          <div style={{ color: 'black' }}>{workOrder?.sub_location?.building}</div>
                        </span>
                      </li>
                    </ul>
                  )}
                  {workOrder?.sub_location?.level && (
                    <ul>
                      <li>
                        <span style={{ marginRight: '50%' }}>
                          Level
                          <div style={{ color: 'black' }}>{workOrder?.sub_location?.level}</div>
                        </span>
                      </li>
                    </ul>
                  )}
                  {workOrder?.sub_location?.suite && (
                    <ul>
                      <li>
                        <span style={{ marginRight: '50%' }}>
                          Suite
                          <div style={{ color: 'black' }}>{workOrder?.sub_location?.suite}</div>
                        </span>
                      </li>
                    </ul>
                  )}
                  {workOrder?.sub_location?.room && (
                    <ul>
                      <li>
                        <span style={{ marginRight: '50%' }}>
                          Room
                          <div style={{ color: 'black' }}>{workOrder?.sub_location?.room}</div>
                        </span>
                      </li>
                    </ul>
                  )}
                </ul>
              )}
            </div>
          </div>
        )}
        {workOrder?.meter?.id && <div className="meter-card col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-3 location-details p-3">
          <div className="card-header m-0 p-0 gcolor">
            <span>New Meter Reading</span>
          </div>
          <div className="card-body mt-2 p-0">
            <div style={{ display: "flex", alignItems: "center" }}>
              <div className="col-lg-8 col-sm-8">
                <input
                  type="text"
                  className="form-control font-weight-normal"
                  name="meter_name"
                  id="meter_name"
                  onChange={handelCurrentReading}
                  value={currentReading}
                  placeholder='Enter new meter reading'
                />
              </div>
              <div className="col-1 cursor-pointer" title="View Meter Details" onClick={openMeterTickect}>
                <FontAwesomeIcon icon={faGaugeHigh} />
              </div>
              <div className="col-2 wrapper-of-attach" title='Upload Reading Attachment'>
                <label className="filebutton" >
                  <FontAwesomeIcon icon={faFile} />
                  <span><input type="file" id="myfile" name="myfile" onChange={handelMeterAttachment} /></span>
                </label>
                {uploadMeterAttachment && <div className='attach-name'>{uploadMeterAttachment?.name.length > 14 ? `${uploadMeterAttachment?.name.slice(0, 14)}...` : uploadMeterAttachment?.name}</div>}
              </div>
            </div>
            <div className='col-12 mt-2 ml-2'>
              <span className='row gcolor'>
                <h6 className='mr-1'>
                  Last Reading:
                </h6>
                <span>
                  {workOrder?.meter?.current_reading}{workOrder?.meter?.measurement_unit} ({moment(workOrder?.meter?.modified_at).fromNow(true)} ago)
                </span>
              </span>
            </div>
          </div>
        </div>}
        {/* <div className="meter-card col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-3 location-details p-3">
          <div className="card-header m-0 p-0 gcolor">
            <span>Summary</span>
          </div>
          <div className="card-body m-0 p-0">
            <div className="row">
              <div className="col-4 row">
                <b className="col-12">Total Cost</b>
                <span style={{ color: '#F63854' }} className="col-12" id="total_ticket_cost">
                  ${totalCostOFWO.toFixed(2)}
                </span>
              </div>
              <div className="col-5 row">
                <b className="col-12">Total Time Spend</b>
                <span className="col-12" style={{ color: '#F63854' }}>
                  {totalHours} hr {min} Min
                </span>
              </div>
            </div>
            <div className="col-12 row mt-3">
              <span >
                <input type="checkbox" className='cost-status' checked={isShowCost} onChange={handelShowCost} />
              </span>
              <span >Include cost in work order report</span>
            </div>
          </div>
        </div> */}
        <div className="meter-card col-lg-12 col-sm-12 col-xs-12 col-md-12 mt-3 location-details p-3">
          <div className="card-header m-0 p-0 gcolor">
            <span>Requestor email</span>
          </div>
          <div className="card-body m-0 p-0">
            <div className="row">
              <div className="col-lg-6 col-sm-12 row">
                <b className="col-12">{workOrder?.service_request?.name}</b>
                <a
                  href={`mailto: ${workOrder?.service_request?.email}`}
                  className="col-12"
                  style={{ color: '#F63854' }}
                >
                  {workOrder?.service_request?.email}
                </a>
              </div>
              <div className="col-lg-6 col-sm-12 row">
                <div className="col-12">Phone</div>
                {workOrder?.service_request?.phone ? (
                  <a
                    href={`tel: ${workOrder?.service_request?.phone}`}
                    className="col-12"
                    style={{ color: '#F63854' }}
                  >
                    {workOrder?.service_request?.phone}
                  </a>
                ) : (
                  <span className="col-12" style={{ color: '#F63854' }}>
                    None
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="pt-2 my-auto">
            <span>
              <b>Issued on</b> {moment(workOrder?.created_at).format('MMMM Do YYYY, h:mm:ss a')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
