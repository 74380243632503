import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';

const getMaintenancesByAuthentication = async () => {
  const res = await http.get(`${BASE_URL}/preventive-maintenance/`);

  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};

const getPagination = async data => {
  const filters = `search=${data.searchValue}&page=${data.currentPage}&page_size=${data.rowPerPage}&site=${data.site}&sublocation=${data.sublocation}&category=${data.filterCategory}&type=${data.type}&assigned_to=${data.staff}&status=${data.status}&is_pm_ticket=${data?.pmSchedule}&start_date=${data?.startDate || ""}&end_date=${data?.endDate || ""}&asset=${data?.asset}&${data.prioirty}&frequency=${data.frequency}`
  const res = await http.get(
    `${BASE_URL}/preventive-maintenance/?${filters}`,
  );
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};

const getMaintenanceById = async MaintenanceId => {
  const res = await http.get(`${BASE_URL}/preventive-maintenance/${MaintenanceId}/`);

  return assert(res, res.data, 'Retrieval of Maintenance by its ID failed', res);
};

const createMaintenance = async MaintenanceData => {
  const res = await http.post(`${BASE_URL}/preventive-maintenance/`, MaintenanceData);

  return assert(res, res.data, 'Creation of Maintenance failed', res);
};

const deleteMaintenance = async MaintenanceId => {
  const res = await http.delete(`${BASE_URL}/preventive-maintenance/${MaintenanceId}/`);
  return assert(res, MaintenanceId, 'Deletion of Maintenance failed', res);
};
const deleteTicketAttachment = async attachId => {
  const res = await http.delete(`${BASE_URL}/ticket-attachment/${attachId}/`);
  return assert(res, attachId, 'Deletion of WorkOrder failed', res);
};
const updateMaintenance = async data => {
  const res = await http.put(`${BASE_URL}/preventive-maintenance/${data.id}/`, data.formData);
  return assert(res, res.data, 'Update of Maintenance failed', res);
};

const generateMaintenanceTicket = async data => {
  const res = await http.post(`${BASE_URL}/preventive-maintenance/generate/`, data);
  return assert(res, res.data, 'Update of Maintenance failed', res);
};

const deleteGenerateMaintenanceTicket = async data => {
  console.log("data", data);
  const res = await http.put(`${BASE_URL}/preventive-maintenance/generate/`, data );
  return assert(res, data, 'Deletion of WorkOrder failed', res);
};

const maintenanceService = {
  getMaintenancesByAuthentication,
  getMaintenanceById,
  createMaintenance,
  deleteMaintenance,
  updateMaintenance,
  getPagination,
  deleteTicketAttachment,
  generateMaintenanceTicket,
  deleteGenerateMaintenanceTicket
};

export default maintenanceService;
