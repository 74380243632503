import { assert, http } from '../../../utils';
import { BASE_URL } from '../../../utils/axios';


const getTicketCommentsByAuthentication = async (id) => {
  const res = await http.get(`${BASE_URL}/ticket-comments/?ticket_id=${id}`);
  return assert(res, res.data, 'Retrieval of asset by its company ID failed', res);
};



const getTicketCommentById = async (ticketCommentId) => {
  const res = await http.get(`${BASE_URL}/ticket-comments/?ticket_id/${ticketCommentId}/`);
  return assert(res, res.data, 'Retrieval of ticketinv by its ID failed', res);
};

const createTicketComment = async (payload) => {
  console.log("payload", payload);
  
  const res = await http.post(`${BASE_URL}/ticket-comments/?ticket_id=${payload.id}`
  , payload);

  return assert(res, res.data, 'Creation of ticketinv failed', res);
};

const deleteTicketComment = async (id) => {
  const res = await http.delete(
    `${BASE_URL}/ticket-comments/${id}/`);
  return assert(res, id, 'Deletion of ticketinv failed', res);
};

const updateTicketComment = async (payload) => {
  const res = await http.put(
    `${BASE_URL}/ticket-comments/${payload.id}/`,
    payload,
  );
  return assert(res, res.data, 'Update of ticketinv failed', res);
};

const ticketComment = {
  getTicketCommentsByAuthentication,
  getTicketCommentById,
  createTicketComment,
  deleteTicketComment,
  updateTicketComment
};

export default ticketComment;
